import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StyledNumber from '../StyledNumber';

import styles from './StyledNumberWithText.module.css';

const StyledNumberWithText = ({ children, className, number }) => {
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.number}>
        <StyledNumber number={number} />
      </div>
      <div>{children}</div>
    </div>
  );
};

StyledNumberWithText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  number: PropTypes.number.isRequired,
};

export default StyledNumberWithText;
