'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';

import styles from './DropdownItem.module.css';

const DropdownItem = React.forwardRef(
  ({ children, className, disabled, onClick, ...props }, ref) => {
    const handleClick = (e) => {
      if (!disabled) onClick?.(e);
    };

    return (
      <RadixDropdown.Item
        {...props}
        className={cn(styles.root, className)}
        disabled={disabled}
        onClick={handleClick}
        ref={ref}>
        {children}
      </RadixDropdown.Item>
    );
  },
);

DropdownItem.displayName = 'DropdownItem';

DropdownItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DropdownItem;
