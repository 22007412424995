import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Eyebrow from '../Eyebrow';
import Heading from '../Heading';
import Stack from '../Stack';
import Text from '../Text';
import TextList from '../TextList';

import styles from './CallToActionCard.module.css';

const CallToActionCard = ({
  actions,
  align = 'left',
  aside,
  bleed = false,
  className,
  eyebrow,
  headline,
  listItems,
  size = 'm',
  subline,
  theme = 'light',
  verticalAlign = 'bottom',
}) => {
  return (
    <div
      className={cn(styles.root, className)}
      data-theme={theme}
      data-size={size}
      data-align={align}
      data-vertical-align={verticalAlign}
      data-bleed={bleed || null}
      data-aside={aside ? 'true' : 'false'}>
      <div className={cn(styles.overlay)} aria-hidden />
      <div className={cn(styles.content)}>
        <div className={cn(styles.inner)}>
          {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
          <Heading as="h3">{headline}</Heading>
          {subline && (
            <Text as="p" size="l">
              {subline}
            </Text>
          )}
          {listItems && (
            <div className={styles.list}>
              <TextList
                listStyle="tick"
                items={listItems}
                style={{ '--tick-icon-color': 'currentColor' }}
              />
            </div>
          )}
          {actions && <div className={cn(styles.actions)}>{actions}</div>}
        </div>
        {aside && (
          <Stack asChild>
            <aside className={cn(styles.aside)}>{aside}</aside>
          </Stack>
        )}
      </div>
    </div>
  );
};

CallToActionCard.propTypes = {
  actions: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right']),
  aside: PropTypes.node,
  bleed: PropTypes.bool,
  className: PropTypes.string,
  eyebrow: PropTypes.string,
  headline: PropTypes.string.isRequired,
  listItems: PropTypes.array,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  subline: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom', 'between']),
};

export default CallToActionCard;
