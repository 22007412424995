import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './StatisticItem.module.css';

const StatisticItem = ({ className, datum, label, size = 's' }) => {
  return (
    <span className={cn(className, styles.root)} data-size={size}>
      {datum && <span className={styles.datum}>{datum}</span>}
      <span className={styles.label}>{label}</span>
    </span>
  );
};

StatisticItem.propTypes = {
  className: PropTypes.string,
  datum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  size: PropTypes.oneOf(['s', 'm']),
};

export default StatisticItem;
