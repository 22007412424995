import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixToggleGroup from '@radix-ui/react-toggle-group';

import Flex from '../../Flex';

import styles from './ToggleGroup.module.css';

export const ToggleGroup = React.forwardRef(
  (
    {
      children,
      className,
      disabled,
      ensureValue = true,
      fullWidth = false,
      hasErrors,
      hasHint,
      hintId,
      id,
      type,
      size = 'm',
      variant = 'default',
      justifyContent = 'center',
      onValueChange = () => {},
      ...props
    },
    forwardedRef,
  ) => {
    const onValueChangeHandler = useCallback(
      (value) => {
        if (type === 'single' && (!ensureValue || value !== ''))
          onValueChange(value);
        if (type === 'multiple' && (!ensureValue || value.length > 0))
          onValueChange(value);
      },
      [onValueChange, ensureValue, type],
    );
    return (
      <Flex gap="2xs" align="stretch" justify={justifyContent} asChild>
        <RadixToggleGroup.Root
          aria-invalid={hasErrors}
          aria-describedby={hasHint ? hintId : null}
          className={cn(styles.root, className)}
          data-disabled={disabled}
          data-variant={variant}
          data-size={size}
          data-full-width={fullWidth || null}
          disabled={disabled}
          id={id}
          type={type}
          onValueChange={onValueChangeHandler}
          ref={forwardedRef}
          {...props}>
          {children}
        </RadixToggleGroup.Root>
      </Flex>
    );
  },
);

ToggleGroup.displayName = 'ToggleGroup';

ToggleGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['single', 'multiple']).isRequired,
  disabled: PropTypes.bool,
  ensureValue: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  justifyContent: PropTypes.oneOf(['start', 'center', 'between']),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onValueChange: PropTypes.func,
  'aria-label': PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  variant: PropTypes.oneOf(['default', 'unstyled']),
};

export const ToggleGroupItem = ({ children, className, ...props }) => {
  return (
    <RadixToggleGroup.Item className={cn(styles.item, className)} {...props}>
      {children}
    </RadixToggleGroup.Item>
  );
};

ToggleGroupItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  asChild: PropTypes.bool,
};

export default ToggleGroupItem;
