import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './LogoSymbol.module.css';

const LogoSymbol = ({ className, variant, size = 'm', fill }) => {
  //width="28" height="18" viewBox="0 0 28 18"
  if (variant === 'distribution') {
    const width = 40;
    const height = 40;
    return (
      <svg
        className={cn(styles.root, className)}
        data-fill={fill}
        viewBox={`0 0 ${width} ${height}`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9422 18.8198V21.8615L14.451 30.6811H11.4913L3 21.8615V18.8198L11.4913 10.0002L14.451 10.0002L22.9422 18.8198ZM12.971 25.2936C15.6085 25.2936 17.7467 23.0762 17.7467 20.341C17.7467 17.6057 15.6085 15.3884 12.971 15.3884C10.3334 15.3884 8.19524 17.6057 8.19524 20.341C8.19524 23.0762 10.3334 25.2936 12.971 25.2936Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3967 19.1621L20.2921 11.2965L21.685 9.86133L30.3967 18.3161V22.3644L21.685 30.8192L20.2921 29.384L28.3967 21.5184V19.1621Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7306 18.4112L30.4177 14.0983L31.8319 12.6841L36.7306 17.5828V23.0978L31.8319 27.9965L30.4177 26.5823L34.7306 22.2694V18.4112Z"
        />
      </svg>
    );
  } else {
    const width = 518;
    const height = 519;
    return (
      <svg
        className={cn(styles.root, className)}
        data-fill={fill}
        viewBox={`0 0 ${width} ${height}`}>
        {size === 'xs' && (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M415 242.236V276.791L276.333 415.014H241.667L103 276.791V242.236L241.667 104.014H276.333L415 242.236ZM258.998 331.243C298.738 331.243 330.954 299.131 330.954 259.518C330.954 219.905 298.738 187.793 258.998 187.793C219.258 187.793 187.042 219.905 187.042 259.518C187.042 299.131 219.258 331.243 258.998 331.243Z"
          />
        )}
        {size === 's' && (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M415 241.736V276.292L276.333 414.514H241.667L103 276.292V241.736L241.667 103.514H276.333L415 241.736ZM258.998 340.145C303.947 340.145 340.386 303.824 340.386 259.019C340.386 214.213 303.947 177.892 258.998 177.892C214.049 177.892 177.61 214.213 177.61 259.019C177.61 303.824 214.049 340.145 258.998 340.145Z"
          />
        )}
        {size === 'm' && (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M414.999 242.236V276.792L276.332 415.014H241.666L102.999 276.792V242.236L241.666 104.014H276.332L414.999 242.236ZM258.998 352.416C310.468 352.416 352.193 310.824 352.193 259.519C352.193 208.214 310.468 166.623 258.998 166.623C207.528 166.623 165.803 208.214 165.803 259.519C165.803 310.824 207.528 352.416 258.998 352.416Z"
          />
        )}
      </svg>
    );
  }
};

LogoSymbol.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['core', 'distribution']),
  size: PropTypes.oneOf(['xs', 's', 'm']),
  fill: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'accent']),
};

export default LogoSymbol;
