'use client';
import React from 'react';
import PropTypes from 'prop-types';

import BaseTokenInput from '../Base/BaseTokenInput';
import asFormInput from '../../../internal/asFormInput';

const TokenInput = React.forwardRef(
  (
    {
      disabled,
      digits = 6,
      value,
      onValueChange,
      name,
      hasErrors,
      hasHint,
      hintId,
      inputId,
      ...rest
    },
    ref,
  ) => {
    return (
      <BaseTokenInput
        ref={ref}
        hasErrors={hasErrors}
        hasHint={hasHint}
        hintId={hintId}
        id={inputId}
        disabled={disabled}
        digits={digits}
        name={name}
        value={value}
        onValueChange={onValueChange}
        {...rest}
      />
    );
  },
);

TokenInput.displayName = 'TokenInput';
TokenInput.propTypes = {
  disabled: PropTypes.bool,
  digits: PropTypes.number,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  name: PropTypes.string,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputId: PropTypes.string,
};

export default asFormInput(TokenInput);
