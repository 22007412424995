'use client';

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { childrenToText } from '../../util/children';
import { ensureArray } from '../../util/array';
import styles from './SelectValueOrPlaceholder.module.css';

const MULTISELECT_ITEM_DISPLAY_LIMIT = 3;

const SelectValueOrPlaceholder = ({
  items,
  disabled,
  value,
  placeholder,
  multiSelect,
  displayLimit = MULTISELECT_ITEM_DISPLAY_LIMIT,
  groupComponentName,
}) => {
  const renderCurrentOptions = useCallback(
    (val) => {
      const filter = (items, _val) => {
        if (_val === null || _val === undefined) return [];
        const val = ensureArray(_val);
        const res = [];
        const childrenArray = React.Children.toArray(items);

        for (let i = 0; i < childrenArray.length; i++) {
          const child = childrenArray[i];
          const isGroup =
            child.type.displayName === groupComponentName ||
            child.type.name === groupComponentName;

          if (isGroup) {
            res.push(filter(child.props.children, val));
          }

          if (val.includes(child.props.value)) res.push(child);
        }

        return res.flat();
      };

      const activeChildren = filter(items, val).filter(Boolean);
      const activeChildrenDisplayValue = activeChildren
        .map((c) => c?.props?.children)
        .filter(Boolean);

      const moreCount = activeChildren.length - displayLimit;

      return activeChildren.length > 0 ? (
        <div className={styles.root} data-disabled={disabled || null}>
          {multiSelect ? (
            <div className={styles.multipleItems}>
              {activeChildren.slice(0, displayLimit).map((c, i) => (
                <div key={i} className={styles.item}>
                  {childrenToText(c)}
                </div>
              ))}
              {moreCount > 0 && <div className={styles.item}>+{moreCount}</div>}
            </div>
          ) : (
            activeChildrenDisplayValue[0]
          )}
        </div>
      ) : (
        placeholder
      );
    },
    [
      items,
      placeholder,
      multiSelect,
      displayLimit,
      groupComponentName,
      disabled,
    ],
  );

  return renderCurrentOptions(value);
};

SelectValueOrPlaceholder.propTypes = {
  items: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  placeholder: PropTypes.node.isRequired,
  displayLimit: PropTypes.number,
  groupComponentName: PropTypes.string.isRequired,
};

export default SelectValueOrPlaceholder;
