'use client';

import React, {
  createContext,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Combobox as AriakitCombobox,
  ComboboxItem as AriakitComboboxItem,
  ComboboxPopover as AriakitComboboxPopover,
  SelectGroup as AriakitSelectGroup,
  SelectGroupLabel as AriakitSelectGroupLabel,
  useComboboxStore,
} from '@ariakit/react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { mergeRefs } from '../../../util/functions';
import HotKey from '../../HotKey';
import Icon from '../../Icon';
import {
  getCurrentList,
  getCurrentTrigger,
  getSearchValue,
  getTrigger,
  replaceValue,
} from './util';

import styles from './SuggestionTextField.module.css';

const SuggestionTextFieldContext = createContext();

const SuggestionTextField = React.forwardRef(
  (
    {
      autoSelect = false,
      canClear = false,
      children,
      clearLabel = 'Clear',
      className,
      disabled,
      hasErrors,
      hasHint,
      hintId,
      id,
      inputClassName,
      name,
      placeholder,
      size = 'm',
      value,
      withIcon = false,
      onClear,
      onEnterKeyPress,
      onValueChange,
      ...props
    },
    forwardedRef,
  ) => {
    const textFieldRef = useRef(null);
    const [trigger, setTrigger] = useState(false);
    const [caretPosition, setCaretPosition] = useState(null);

    const triggers = useMemo(() => {
      const result = [];
      React.Children.map(children, (child) => {
        if (child && child.type && child.type === SuggestionTextFieldTrigger) {
          result.push({
            trigger: child.props.trigger,
            items: child.props.children,
            autoSelect: child.props.autoSelect,
          });
        }
      });

      return result;
    }, [children]);

    const triggerNames = useMemo(() => {
      return triggers.map((trigger) => trigger.trigger);
    }, [triggers]);

    const combobox = useComboboxStore();
    const searchValue = combobox.useState('value');
    const deferredSearchValue = useDeferredValue(searchValue);

    const checkForTrigger = (element) => {
      const trigger = getTrigger(element, triggerNames);
      const searchValue = getSearchValue(element, trigger);

      if (trigger !== false) {
        setTrigger(trigger);
        combobox.show();
      } else if (!searchValue) {
        setTrigger(false);
        combobox.hide();
      }

      combobox.setValue(searchValue);
    };

    const handleChange = (event) => {
      checkForTrigger(event.target);
      onValueChange(event.target.value);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        combobox.hide();
      }

      /**
       * AriakitCombobox works with virtual focus, which means
       * from the browser’s perspective the input field always
       * remains focused, event when using the arrow keys to pick
       * from the combobox popover.
       *
       * This means a user hitting Enter to select the highlighted
       * value would also count as an Enter key press on the input.
       *
       * To prevent this, we use the data-active-item attribute
       * that React Ariakit adds to the currently virtually focused
       * item and only trigger the onEnterKeyPress callback if the
       * input is the active element.
       */
      if (
        event.key === 'Enter' &&
        event.target.getAttribute('data-active-item')
      ) {
        combobox.hide();
        onEnterKeyPress?.();
      }
    };

    const handleFocus = (event) => {
      event.target.setSelectionRange(
        event.target.value.length,
        event.target.value.length,
      );

      checkForTrigger(event.target);
    };

    const handleBlur = () => {
      combobox.hide();
    };

    const handleItemClick = (selectedValue, replaceTrigger) => () => {
      const input = textFieldRef.current;
      if (!input) return;

      const { newValue, newCaretOffset } = replaceValue({
        element: input,
        searchValue,
        value: selectedValue,
        prevValue: value,
        trigger,
        replaceTrigger,
      });

      onValueChange(newValue);
      setCaretPosition(newCaretOffset);

      /**
       * When picking a value we immediately check if this value
       * is a trigger itself and if so keep the combobox open.
       */
      if (triggerNames.includes(selectedValue)) {
        requestAnimationFrame(() => checkForTrigger(input));
      } else {
        setTrigger(false);
      }
    };

    const [matches, currentTrigger] = useMemo(() => {
      const currentTrigger = getCurrentTrigger(trigger, triggers);
      const list = getCurrentList(trigger, triggers);
      if (deferredSearchValue === trigger) return list;

      let matchCount = 0;

      /**
       * Recursively filter the list of children to find
       * matches based on the search value.
       *
       * If a group is found, the children of that group
       * are filtered again. Similar to how it is handled
       * in the Combobox component.
       */
      const filter = (children, searchValue) =>
        React.Children.map(children, (child) => {
          const isGroup = child?.type === SuggestionTextFieldGroup;
          const isItem = child?.props?.searchValue ?? child?.props?.value;

          if (isGroup) {
            return {
              ...child,
              props: {
                ...child.props,
                children: filter(child.props.children, searchValue),
              },
            };
          }
          if (
            isItem &&
            (child.props.searchValue ?? child.props.value)
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            matchCount++;
            return child;
          }
        });

      const matches = filter(list, deferredSearchValue);

      return matchCount > 0 ? [matches, currentTrigger] : [[], currentTrigger];
    }, [deferredSearchValue, triggers, trigger]);

    const hasMatches = matches && matches.length > 0;

    useEffect(() => {
      if (caretPosition === null) return;
      textFieldRef.current?.setSelectionRange(caretPosition, caretPosition);
    }, [caretPosition]);

    return (
      <SuggestionTextFieldContext.Provider value={{ handleItemClick }}>
        <div className={cn(styles.root, className)} data-size={size}>
          <div className={styles.inputWrapper}>
            {withIcon && (
              <Icon
                aria-hidden="true"
                icon="Search"
                className={styles.inputIcon}
                fill="tertiary"
              />
            )}
            <AriakitCombobox
              store={combobox}
              autoSelect={currentTrigger?.autoSelect ?? autoSelect}
              value={value}
              showOnClick={false}
              showOnChange={false}
              showOnKeyPress={false}
              setValueOnChange={false}
              render={
                <input
                  ref={mergeRefs(textFieldRef, forwardedRef)}
                  aria-describedby={hasHint ? hintId : null}
                  aria-invalid={hasErrors ?? null}
                  className={cn(styles.textField, inputClassName)}
                  disabled={disabled}
                  id={id}
                  name={name}
                  placeholder={placeholder}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onKeyDown={handleKeyDown}
                  {...props}
                />
              }
            />
            <AriakitComboboxPopover
              autoFocusOnHide={false}
              unmountOnHide
              store={combobox}
              hidden={!hasMatches}
              overflowPadding={0}
              className={styles.menu}
              fitViewport
              sameWidth>
              <div className={styles.listbox}>{matches}</div>
            </AriakitComboboxPopover>
            <div className={styles.actions}>
              {onEnterKeyPress && value && (
                <HotKey className={styles.enterHint} aria-hidden="true">
                  &#9166;
                </HotKey>
              )}
              {canClear && value && (
                <button
                  aria-label={clearLabel}
                  className={styles.clearButton}
                  disabled={disabled}
                  type="button"
                  size="xs"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear?.();
                  }}>
                  <Icon size="s" icon="Close" />
                </button>
              )}
            </div>
          </div>
        </div>
      </SuggestionTextFieldContext.Provider>
    );
  },
);

SuggestionTextField.displayName = 'SuggestionTextField';

SuggestionTextField.propTypes = {
  autoSelect: PropTypes.bool,
  canClear: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  clearLabel: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  value: PropTypes.string,
  withIcon: PropTypes.bool,
  onClear: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  onValueChange: PropTypes.func,
};

export const SuggestionTextFieldTrigger = ({ trigger, children }) => (
  <span data-trigger={trigger}>{children}</span>
);

SuggestionTextFieldTrigger.propTypes = {
  autoSelect: PropTypes.bool,
  trigger: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export const SuggestionTextFieldItem = ({
  disabled,
  value,
  searchValue,
  replaceTrigger = false,
  children,
}) => {
  const { handleItemClick } = useContext(SuggestionTextFieldContext);

  return (
    <AriakitComboboxItem
      data-search-value={searchValue}
      focusOnHover
      className={styles.item}
      disabled={disabled}
      value={value}
      onClick={handleItemClick(value, replaceTrigger)}>
      {children}
      <HotKey className={styles.itemEnterHint} aria-hidden="true">
        &#9166;
      </HotKey>
    </AriakitComboboxItem>
  );
};

SuggestionTextFieldItem.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  searchValue: PropTypes.string,
  children: PropTypes.node.isRequired,
  replaceTrigger: PropTypes.bool,
};

export const SuggestionTextFieldGroup = ({ children, label }) => {
  return (
    <AriakitSelectGroup className={styles.group}>
      {label && (
        <AriakitSelectGroupLabel className={styles.groupLabel}>
          {label}
        </AriakitSelectGroupLabel>
      )}
      {children}
    </AriakitSelectGroup>
  );
};

SuggestionTextFieldGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

export default SuggestionTextField;
