'use client';

import React from 'react';

import * as RadixPopover from '@radix-ui/react-popover';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './PopoverContent.module.css';

const PopoverContent = ({
  arrow = true,
  arrowHeight = '10',
  arrowWidth = '20',
  children,
  className,
  side = 'bottom',
  variant,
  width = 'm',
  ...rest
}) => {
  const props = {
    'data-variant': variant,
    'data-width': width,
  };

  return (
    <RadixPopover.Portal>
      <RadixPopover.Content
        className={cn(styles.content, className)}
        sideOffset={10}
        side={side}
        {...props}
        {...rest}>
        <div className={styles.inner}>{children}</div>
        {arrow && (
          <RadixPopover.Arrow
            width={arrowWidth}
            height={arrowHeight}
            className={styles.arrow}
          />
        )}
      </RadixPopover.Content>
    </RadixPopover.Portal>
  );
};

PopoverContent.propTypes = {
  arrow: PropTypes.bool,
  arrowHeight: PropTypes.number,
  arrowWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  side: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  variant: PropTypes.oneOf(['accent', 'dark', 'light', 'onboarding', 'input']),
  width: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'auto']),
};

export default PopoverContent;
