import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { ReactTags } from 'react-tag-autocomplete';

import Icon from '../../../components/Icon';

import styles from './TagField.module.css';

const TagOption = ({ children, classNames, option, ...optionProps }) => {
  const classes = [
    classNames.option,
    option.active ? styles.optionIsActive : '',
  ];
  return (
    <div className={classes.join(' ')} {...optionProps}>
      {children}
      {option.selected && (
        <Icon icon="Tick" className={styles.selectedOptionIcon} />
      )}
    </div>
  );
};

TagOption.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.object,
  option: PropTypes.object.isRequired,
};

const TagField = React.forwardRef(
  (
    {
      activateFirstOption = false,
      allowNew = true,
      className,
      deleteButtonText = 'Remove tag: %value%',
      disabled,
      hasErrors,
      hasHint,
      hintId,
      id,
      name,
      newOptionText = 'Add new tag: %value%',
      noOptionsText = 'No options found for %value%',
      onAdd,
      onDelete,
      placeholder = 'Add a tag',
      size = 'm',
      tagListLabelText = 'Selected tags',
      value = [],
      variant = 'default',
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <div
        className={cn(className, styles.root)}
        aria-invalid={hasErrors}
        data-variant={variant}
        data-size={size}>
        <ReactTags
          classNames={{
            root: styles.tagsRoot,
            rootIsActive: styles.tagsRootIsActive,
            rootIsDisabled: styles.tagsRootIsDisabled,
            rootIsInvalid: styles.tagsRootIsInValid,
            label: styles.label,
            tagList: styles.tagList,
            tagListItem: styles.tagListItem,
            tag: styles.tag,
            tagName: styles.tagName,
            comboBox: styles.comboBox,
            input: styles.comboBoxInput,
            listBox: styles.listBox,
            option: styles.option,
            optionIsActive: styles.optionIsActive,
            highlight: styles.highlight,
          }}
          activateFirstOption={activateFirstOption}
          allowNew={allowNew}
          aria-describedby={hasHint ? hintId : null}
          aria-invalid={hasErrors ?? null}
          deleteButtonText={deleteButtonText}
          id={id}
          isDisabled={disabled}
          isInvalid={hasErrors ?? null}
          labelText={placeholder}
          name={name}
          newOptionText={newOptionText}
          noOptionsText={noOptionsText}
          onAdd={(newTag) => onAdd(newTag)}
          onDelete={(tagIndex) => onDelete(tagIndex)}
          placeholderText={placeholder}
          ref={forwardedRef}
          renderOption={TagOption}
          selected={value}
          tagListLabelText={tagListLabelText}
          {...rest}
        />
      </div>
    );
  },
);

TagField.propTypes = {
  activateFirstOption: PropTypes.bool,
  allowNew: PropTypes.bool,
  className: PropTypes.string,
  deleteButtonText: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  newOptionText: PropTypes.string,
  noOptionsText: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  tagListLabelText: PropTypes.string,
  value: PropTypes.array,
  variant: PropTypes.oneOf(['default', 'unstyled']),
};

TagField.displayName = 'TagField';

export default TagField;
