'use client';

import React from 'react';
import PropTypes from 'prop-types';

import { Combobox } from '../Base/Combobox';
import asFormInput from '../../../internal/asFormInput';

const ComboboxInput = React.forwardRef(
  (
    { children, hasErrors, hasHint, hintId, inputId, ...props },
    forwardedRef,
  ) => {
    return (
      <Combobox
        hasErrors={hasErrors}
        hasHint={hasHint}
        hintId={hintId}
        id={inputId}
        ref={forwardedRef}
        {...props}>
        {children}
      </Combobox>
    );
  },
);

ComboboxInput.displayName = 'ComboboxInput';
ComboboxInput.propTypes = {
  children: PropTypes.node.isRequired,
  forwardedRef: PropTypes.func,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputId: PropTypes.string,
};

export default asFormInput(ComboboxInput);
