import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Callout.module.css';

const Callout = ({
  action,
  children,
  className,
  icon,
  role,
  size = 'm',
  variant = 'info',
  balancedText = false,
}) => {
  return (
    <div
      className={cn(styles.root, className)}
      data-size={size}
      data-variant={variant}
      role={role}>
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      <div className={styles.text} data-balanced-text={balancedText}>
        {children}
      </div>
      {action && <div className={styles.actionWrapper}>{action}</div>}
    </div>
  );
};

Callout.propTypes = {
  action: PropTypes.node,
  balancedText: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  role: PropTypes.oneOf(['alert']),
  size: PropTypes.oneOf(['s', 'm', 'l']),
  variant: PropTypes.oneOf([
    'error',
    'info',
    'premium',
    'subdued',
    'success',
    'warning',
  ]),
};

export default Callout;
