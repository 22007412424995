import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './LoadingText.module.css';

const LoadingText = ({
  characters = 20,
  className,
  hasAnimation = true,
  size = 'm',
}) => {
  return (
    <div
      aria-busy="true"
      data-animated={hasAnimation}
      className={cn(styles.root, className)}
      data-size={size}
      style={{
        '--skeleton-width': `${characters}ch`,
      }}
    />
  );
};

LoadingText.propTypes = {
  characters: PropTypes.number,
  className: PropTypes.string,
  hasAnimation: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
};

export default LoadingText;
