export const scales = {
  linear: (t) => t,
  quadratic: (t) => t * t,
  cubic: (t) => t * t * t,
  sqrt: (t) => Math.sqrt(t),
};

export const inverseScales = {
  linear: (t) => t,
  quadratic: (t) => Math.sqrt(t),
  cubic: (t) => Math.cbrt(t),
  sqrt: (t) => t * t,
};
