'use client';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useVirtualizer } from '@tanstack/react-virtual';

const VirtualizedSelectList = ({ items, className, estimatedItemSize }) => {
  const scrollerRef = useRef();
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => scrollerRef.current,
    estimateSize: () => estimatedItemSize,
  });

  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={scrollerRef}
      className={className}
      style={{
        height: `min(calc(${virtualizer.getTotalSize()}px + 2 * var(--listbox-spacing, var(--space-2xs))), var(--listbox-max-height, 250px))`,
        contain: 'strict',
      }}>
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: '100%',
          position: 'relative',
        }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}>
          {virtualItems.map((virtualRow) => (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}>
              {items[virtualRow.index]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

VirtualizedSelectList.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  estimatedItemSize: PropTypes.number.isRequired,
};

export default VirtualizedSelectList;
