'use client';

import React from 'react';

import PropTypes from 'prop-types';

import asFormInput from '../../../internal/asFormInput';
import TagField from '../Base/TagField';

const TagFieldInput = React.forwardRef(
  ({ hasErrors, hasHint, hintId, inputId, ...rest }, forwardedRef) => {
    return (
      <TagField
        hasErrors={hasErrors}
        hasHint={hasHint}
        hintId={hintId}
        id={inputId}
        ref={forwardedRef}
        {...rest}
      />
    );
  },
);

TagFieldInput.displayName = 'TagFieldInput';
TagFieldInput.propTypes = {
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputId: PropTypes.string,
};

export default asFormInput(TagFieldInput);
