'use client';

import * as RadixDialog from '@radix-ui/react-dialog';

import DialogPanel from './DialogPanel';

const Dialog = RadixDialog.Root;
const DialogTrigger = RadixDialog.Trigger;

export { Dialog, DialogTrigger, DialogPanel };
