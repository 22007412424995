'use client';

import React, { Children, useId, useRef } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { Close } from '@cloudsmith/icons';

import Button, { ButtonIcon } from '../../Button';
import { Combobox, ComboboxItem } from '../../Combobox';
import Flex from '../../Flex';
import Stack from '../../Stack';
import Text from '../../Text';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';

import styles from './MultiSelectList.module.css';

export const MultiSelectList = React.forwardRef(
  (
    {
      addFirstItemLabel = 'Add item',
      addAnotherItemLabel = 'Add another item',
      canClearAll = true,
      clearAllLabel = 'Clear all',
      children,
      className,
      disabled,
      hasErrors,
      hasHint,
      hintId,
      noResultsMessage,
      placeholder = 'Add item',
      selectAll = true,
      selectAllLabel,
      selectedLabel = 'item(s) selected',
      searchPlaceholder,
      value = [],
      virtualizeList,
      onValueChange,
    },
    forwardedRef,
  ) => {
    const inputId = useId();
    const listRef = useRef(null);

    const selectedItems = Children.map(children, (child) => {
      if (value.includes(child.props.value)) return child;
    }).sort((a, b) => {
      return value.indexOf(a.props.value) - value.indexOf(b.props.value);
    });

    const selectableItems = Children.map(children, (child) => {
      if (!value.includes(child.props.value)) return child;
    });

    const handleAddValue = (v) => {
      onValueChange([...value, ...v]);

      if (listRef.current) {
        // Make sure we let React re-render the component
        // before dispatching the scroll, so we can actually
        // scroll to update element height
        setTimeout(() => {
          listRef.current.scrollTo({
            top: listRef.current.scrollHeight,
          });
        }, 1);
      }
    };

    const handleRemoveValue = (v) => {
      onValueChange(value.filter((value) => value !== v));
    };

    const clearAll = () => onValueChange([]);

    const handleSelectAll = () => {
      const items = Children.toArray(selectableItems)
        .filter((child) => !child.props.disabled)
        .map((child) => child.props.value);

      onValueChange([...value, ...items]);
    };

    const isEmpty = selectedItems.length === 0;
    const hasSelectableItems = selectableItems.length > 0;

    return (
      <Stack
        className={cn(styles.root, className)}
        space="2xs"
        data-disabled={disabled || null}
        data-has-errors={hasErrors || null}>
        <div
          ref={listRef}
          className={styles.selectedItemsWrapper}
          data-empty={isEmpty || null}>
          {isEmpty && (
            <Flex
              align="center"
              justify="center"
              className={styles.empty}
              asChild>
              <label htmlFor={hasSelectableItems ? inputId : null}>
                <Text size="s" color="secondary">
                  {placeholder}
                </Text>
              </label>
            </Flex>
          )}

          {!isEmpty && (
            <>
              <div className={styles.selectedItemsHeader}>
                <Text size="s" color="secondary">
                  {selectedItems.length} {selectedLabel}
                </Text>
                {canClearAll && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <button
                        aria-label={clearAllLabel}
                        onClick={clearAll}
                        className={styles.clearButton}>
                        <Close />
                      </button>
                    </TooltipTrigger>
                    <TooltipContent>{clearAllLabel}</TooltipContent>
                  </Tooltip>
                )}
              </div>
              <div className={styles.selectedItems}>
                {selectedItems.map((selectedItem) => (
                  <div
                    key={selectedItem.props.value}
                    className={styles.selectedItem}>
                    <div className={styles.itemContainer}>{selectedItem}</div>
                    <div className={styles.removeButton}>
                      <Button
                        aria-label="Remove"
                        variant="ghost"
                        size="xs"
                        colorScheme="text"
                        onClick={() =>
                          handleRemoveValue(selectedItem.props.value)
                        }>
                        <ButtonIcon>
                          <Close />
                        </ButtonIcon>
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        {hasSelectableItems && (
          <Combobox
            value={null}
            onValueChange={handleAddValue}
            disabled={disabled}
            dropdownPosition="above"
            id={inputId}
            hasErrors={hasErrors}
            hasHint={hasHint}
            hintId={hintId}
            noResultsMessage={noResultsMessage}
            placeholder={isEmpty ? addFirstItemLabel : addAnotherItemLabel}
            searchPlaceholder={searchPlaceholder}
            ref={forwardedRef}
            selectAll={
              selectAll && selectableItems.length > 1 ? handleSelectAll : null
            }
            selectAllLabel={selectAllLabel}
            multiSelect
            virtualizeList={virtualizeList}>
            {Children.map(selectableItems, (item) => (
              <ComboboxItem {...item.props} />
            ))}
          </Combobox>
        )}
      </Stack>
    );
  },
);

MultiSelectList.propTypes = {
  addFirstItemLabel: PropTypes.string,
  addAnotherItemLabel: PropTypes.string,
  canClearAll: PropTypes.bool,
  clearAllLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  noResultsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  selectAll: PropTypes.bool,
  selectAllLabel: PropTypes.string,
  selectedLabel: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  virtualizeList: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
};

MultiSelectList.displayName = 'MultiSelectList';

export const MultiSelectListItem = ({
  children,
  disabled,
  value,
  searchValue,
  ...props
}) => {
  return (
    <div
      aria-disabled={disabled || null}
      data-value={value}
      data-searchvalue={searchValue}
      {...props}>
      {children}
    </div>
  );
};

MultiSelectListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
};
