import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);

/**
 * Formats a file size given in bytes into a human-readable string.
 *
 * @param {number} bytes - The file size in bytes to format.
 * @param {number} [places=1] - The number of decimal places to include in the formatted size.
 * @param {number} [thresholdBase=1000] - The threshold base to use for conversion. Use 1000 for metric units (kB, MB, GB, etc.) or 1024 for binary units.
 * @returns {{ size: string, unit: string }} An object containing the formatted size and the appropriate unit.
 *
 */
export const formatFileSize = (bytes, places = 1, thresholdBase = 1000) => {
  const thresh = thresholdBase;

  if (Math.abs(bytes) < thresh) {
    return {
      size: bytes,
      unit: 'B',
    };
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let u = -1;
  const r = 10 ** places;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return {
    size: bytes.toFixed(places),
    unit: units[u],
  };
};

/**
 * Formats a number with commas.
 *
 * @param {number} num
 * @param {number} places Number of decimal places to display
 *
 * @return {string}
 */
export const formatNumber = (num, places = 0) => {
  return Number(num)
    .toFixed(places)
    .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
};

/**
 * Formats and abbreviates a number
 *
 * @param {number} num
 * @param {number} places Number of decimal places to display
 *
 * @return {string}
 */
export const formatNumberAbbreviator = (num, places = 1) => {
  num = Number(num);
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(places).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(places).replace(/\.0$/, '') + 'M';
  }
  if (num >= 10_000) {
    let formatted = (num / 1_000).toFixed(places).replace(/\.0$/, '');
    if (formatted === '1000') {
      return '1M';
    }
    return formatted + 'K';
  }
  if (num >= 1_000) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return num.toString();
};

/**
 * Formats a number into a price format
 *
 * @param {number} num
 * @param {string} currency e.g. USD, JPY, EUR, GBP etc.
 * @param {number} minimumFractionDigits The minimum number of integer digits to use.
 *
 * @return {string}
 */
export const formatPrice = (
  num,
  currency = 'USD',
  minimumFractionDigits = 2,
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits,
  }).format(num);
};

/**
 * Takes a date string and returns an object with the time and unit.
 *
 * @param {string} input
 * @return {{ time: string, unit: string }}
 */
export const formatTimeAgo = (input) => {
  const formatter = new TimeAgo('en-GB');
  const formatted = formatter.format(new Date(input));

  const [time, ...unit] = formatted.split(' ');

  return {
    time,
    unit: unit.join(' '),
  };
};

/**
 * Formats a pretty date string
 *
 * @param {string|Date} input
 * @returns {string}
 */
export const formatPrettyDate = (
  dateString,
  locale = undefined,
  showTime = false,
  showSeconds = false,
  showMilliseconds = false,
  useUTC = false,
  showTimeZone = true,
  monthFormat = 'long',
) => {
  const date = new Date(dateString);

  const options = {
    year: 'numeric',
    month: monthFormat,
    day: 'numeric',
    hour: showTime ? (useUTC ? '2-digit' : 'numeric') : undefined,
    minute: showTime ? 'numeric' : undefined,
    second: showTime && showSeconds ? 'numeric' : undefined,
    fractionalSecondDigits: showMilliseconds ? 3 : undefined,
    hour12: !useUTC,
    timeZone: useUTC ? 'UTC' : undefined,
    timeZoneName: showTime && showTimeZone ? 'short' : undefined,
  };

  const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);

  return formattedDate;
};

/**
 * Formats UTC date and time into YYYY-MM-DD / HH:MM:SS UTC format
 *
 * @param {string|Date} input
 * @returns {string}
 */
export const formatUTCDatetime = (input) => {
  const date = new Date(input);

  const year = `${date.getUTCFullYear()}`;
  const month = `${date.getUTCMonth() + 1}`;
  const day = `${date.getUTCDate()}`;
  const hours = `${date.getUTCHours()}`;
  const minutes = `${date.getUTCMinutes()}`;
  const seconds = `${date.getUTCSeconds()}`;

  return `${year}-${month.padStart(2, '0')}-${day.padStart(
    2,
    '0',
  )} ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(
    2,
    '0',
  )} UTC`;
};

/**
 * Formats a date into a month name
 * Example: 'March'
 *
 * @param {string|Date} input
 * @returns {string}
 */
export const formatLongMonth = (input) => {
  const date = new Date(input);
  return date.toLocaleDateString(undefined, {
    month: 'long',
  });
};

/**
 * Converts a given number of hours into an object with the number of days and hours.
 *
 * @param {number} hours
 * @returns {{ days: number, hours: number }}
 *
 */
export const convertHoursToDaysAndHours = (hours) => {
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;

  return {
    days,
    hours: remainingHours,
  };
};

/**
 * Turns a string, array, or object of strings into sentence case
 * rune -> Rune
 *
 * @param {string|Array|Object} input
 * @returns {string|Array|Object}
 */
export const upCase = (input) => {
  const doIt = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  if (typeof input === 'string') {
    return doIt(input);
  } else if (Array.isArray(input)) {
    return input.map((str) => doIt(str));
  } else if (input) {
    const results = {};
    Object.keys(input).map((key) => (results[key] = doIt(input[key])));
    return results;
  }
  return;
};

/**
 * Turns a sentence, or space separated words,
 * into a camel case expression
 *
 * @param {string} input
 * @returns {string}
 */
export const camelCase = (input) => {
  return input
    .toLowerCase()
    .split(' ')
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join('');
};

/**
 * Takes a singular and plural string and returns the right on based on count
 *
 * @param {Array} terms
 * @param {number} count
 * @returns {string|Array|Oject}
 */
export const pluralize = (terms, count) => {
  return count === 1 ? terms[0] : terms[1];
};

/**
 * Interpolates an i18n string in the client
 *
 * @param {string} str
 * @param {object} vars
 */
export const interpolate = (str, vars) => {
  const matches = [...str.matchAll(/{{(?<key>[^}\s-]+)}}/g)];
  const keys = matches.map((m) => m.groups.key);
  let copy = str;
  for (const key of keys) {
    if (vars[key] !== undefined) {
      copy = copy.replaceAll(`{{${key}}}`, vars[key]);
    }
  }
  return copy;
};

/**
 * Shorten a string to x chars with ... at the end
 *
 * @param {string} str
 * @param {number} numChars
 * @returns {string}
 */
export const shorten = (str, numChars) => {
  if (!str) return '';

  if (str.length <= numChars) {
    return str;
  }

  return str.substring(0, numChars - 2) + '...';
};

/**
 * Cloudsmith api endpoints return 'display' file sizes as pre-built strings
 * e.g. 0 B, 9.5 MB, 1 GB
 *
 * Returns an object with value and unit extracted
 *
 * @param {string} str
 * @returns {object}
 */

export const extractFileSize = (str) => {
  const fileSizePattern = /^(\d+(\.\d+)?)\s*(B|KB|MB|GB|TB|PB)$/i;
  const match = str.match(fileSizePattern);

  if (!str) return '';
  if (!match) {
    return str;
  }

  const value = parseFloat(match[1]);
  const unit = match[3].toUpperCase();

  return { value, unit };
};

/**
 * Ensure an external href has http prefix
 *
 * @param {string} url
 * @param {bool} https
 * @returns {string}
 */
export const formatHref = (url, https) => {
  if (!url) return '';

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    if (https) {
      url = 'https://' + url;
    } else {
      url = 'http://' + url;
    }
  }
  return url;
};

/**
 * Formats a package name for use in a URI
 * but making it as legible as possible by
 * retaining any @ characters and replacing / with _
 *
 * e.g. '@next/swc-darwin-x64' -> '@next_swc-darwin-x64'
 *
 * @param {string} str
 * @returns {string}
 */
export const formatPackageNameUri = (str) => {
  return encodeURIComponent(str)
    .replace(/%40/g, '@')
    .replace(/%2F/g, '_')
    .replace(/%20/g, '-');
};
