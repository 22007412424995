'use client';

import * as RadixDropdown from '@radix-ui/react-dropdown-menu';

import DropdownItem from './DropdownItem';
import DropdownMenu from './DropdownMenu';
import DropdownMenuSeparator from './DropdownMenuSeparator';

const Dropdown = RadixDropdown.Root;
const DropdownTrigger = RadixDropdown.Trigger;

export {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownMenuSeparator,
  DropdownItem,
};
