'use client';

import React from 'react';

import * as RadixTooltip from '@radix-ui/react-tooltip';
import PropTypes from 'prop-types';

const Tooltip = ({ children, delayDuration = 100, ...rest }) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={delayDuration} {...rest}>
        {children}
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  delayDuration: PropTypes.number,
};

export default Tooltip;
