'use client';

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '../Base/Select';
import asFormInput from '../../../internal/asFormInput';

const SelectInput = React.forwardRef(
  (
    { children, hasErrors, hasHint, hintId, inputId, ...props },
    forwardedRef,
  ) => {
    return (
      <Select
        hasErrors={hasErrors}
        hasHint={hasHint}
        hintId={hintId}
        id={inputId}
        ref={forwardedRef}
        {...props}>
        {children}
      </Select>
    );
  },
);

SelectInput.displayName = 'SelectInput';
SelectInput.propTypes = {
  children: PropTypes.node.isRequired,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputId: PropTypes.string,
};

export default asFormInput(SelectInput);
