'use client';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Item } from '@radix-ui/react-accordion';

import styles from './AccordionItem.module.css';

const AccordionItem = ({ className, ...props }) => {
  return <Item className={cn(styles.root, className)} {...props} />;
};

AccordionItem.propTypes = {
  className: PropTypes.string,
};

export default AccordionItem;
