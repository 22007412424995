import { Children, isValidElement } from 'react';

/**
 * Turns a single React child into a string
 *
 * @param {ReactNode} child
 * @returns {string}
 * @private
 */
const childToString = (child) => {
  if (
    typeof child === 'undefined' ||
    child === null ||
    typeof child === 'boolean'
  )
    return '';

  if (isValidElement(child)) return '';
  if (JSON.stringify(child) === '{}') return '';

  return child.toString();
};

/**
 * Checks if a React child has children
 *
 * @param {ReactNode} child
 * @returns {boolean}
 * @private
 */
const hasChildren = (child) => {
  return isValidElement(child) && Boolean(child.props.children);
};

/**
 * Turns React children into text only
 *
 * Inspired by react-children-utilities onlyText method
 * See: https://github.com/fernandopasik/react-children-utilities/blob/main/src/lib/onlyText.ts
 *
 * @param {ReactNode} children
 * @returns {string}
 * @example
 * childrenToText('foo') // 'foo'
 * childrenToText('<div>foo</div>') // 'foo'
 */
export const childrenToText = (children) => {
  if (!(children instanceof Array) && !isValidElement(children)) {
    return childToString(children);
  }

  return Children.toArray(children).reduce((text, child) => {
    let newText = '';

    if (hasChildren(child)) {
      newText = childrenToText(child.props.children);
    } else {
      newText = childToString(child);
    }

    return text.concat(newText);
  }, '');
};
