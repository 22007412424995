'use client';

import React from 'react';
import PropTypes from 'prop-types';

import { FilePicker } from '../Base/FilePicker';
import asFormInput from '../../../internal/asFormInput';

const FilePickerInput = React.forwardRef(
  (
    { hasErrors, hasHint, hintId, inputId, ...props },
    forwardedRef,
  ) => {
    return (
      <FilePicker
        hasErrors={hasErrors}
        hasHint={hasHint}
        hintId={hintId}
        id={inputId}
        ref={forwardedRef}
        {...props}
      />
    );
  },
);

FilePickerInput.displayName = 'FilePickerInput';
FilePickerInput.propTypes = {
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputId: PropTypes.string,
};

export default asFormInput(FilePickerInput);
