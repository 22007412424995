'use client';

import React, { useId } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Explainer from '../../Explainer';
import SwitchToggle from '../Base/SwitchToggle';

import styles from './SwitchToggleInput.module.css';

const SwitchToggleInput = ({
  checked,
  className,
  disabled,
  errors,
  explainer,
  hint,
  label,
  name,
  onCheckedChange,
}) => {
  const inputId = useId();
  const hintId = useId();

  return (
    <div
      className={cn(styles.root, className)}
      data-disabled={disabled ? 'disabled' : null}>
      <div className={cn(styles.inputWrapper)}>
        <SwitchToggle
          disabled={disabled}
          id={inputId}
          name={name}
          aria-describedby={hint ? hintId : null}
          checked={checked}
          onCheckedChange={onCheckedChange}
        />
      </div>
      <div>
        <div className={cn(styles.labelWrapper)}>
          <label className={cn(styles.label)} htmlFor={inputId}>
            {label}
          </label>
          {explainer && <Explainer explainer={explainer} />}
        </div>

        {hint && !errors && (
          <div className={cn(styles.hint)} id={hintId}>
            {hint}
          </div>
        )}

        {errors && (
          <div className={cn(styles.error)} role="alert">
            {errors.message}
          </div>
        )}
      </div>
    </div>
  );
};

SwitchToggleInput.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  explainer: PropTypes.node,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onCheckedChange: PropTypes.func,
};

export default SwitchToggleInput;
