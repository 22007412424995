'use client';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import Tooltip from './Tooltip';
import TooltipContent from './TooltipContent';

const TooltipTrigger = RadixTooltip.Trigger;

export { Tooltip, TooltipTrigger, TooltipContent };
