'use client';

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as RadixDialog from '@radix-ui/react-dialog';

import { Close } from '@cloudsmith/icons';

import VisuallyHidden from '../VisuallyHidden';

import styles from './DialogPanel.module.css';

const ConditionalA11yContent = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const DialogPanel = ({
  children,
  className,
  description,
  title,
  verticalPosition = 'top',
  visuallyHideA11yTitle = false,
  width = 'm',
}) => {
  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay
        className={styles.overlay}
        data-vertical-position={verticalPosition}>
        <RadixDialog.Content
          className={cn(styles.content, className)}
          data-width={width}
          data-vertical-position={verticalPosition}>
          <ConditionalA11yContent
            condition={visuallyHideA11yTitle}
            wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}>
            <RadixDialog.Title className={styles.title}>
              {title}
            </RadixDialog.Title>
            {description && (
              <RadixDialog.Description className={styles.description}>
                {description}
              </RadixDialog.Description>
            )}
          </ConditionalA11yContent>

          {children}

          <RadixDialog.Close asChild>
            <button className={styles.closeButton} aria-label="Close overlay">
              <Close className={styles.closeIcon} />
            </button>
          </RadixDialog.Close>
        </RadixDialog.Content>
      </RadixDialog.Overlay>
    </RadixDialog.Portal>
  );
};

DialogPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  verticalPosition: PropTypes.oneOf(['top', 'center']),
  visuallyHideA11yTitle: PropTypes.bool,
  width: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl']),
};

export default DialogPanel;
