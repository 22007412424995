'use client';

import React, { useState } from 'react';

import cn from 'classnames';
import Image from 'next/image';
import PropTypes from 'prop-types';

import styles from './Avatar.module.css';

const Avatar = ({
  alt,
  className,
  fallback,
  size = 'xs',
  src,
  fill = 'default',
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  return (
    <div
      aria-label={alt}
      className={cn(styles.root, className)}
      data-fill={fill}
      data-size={size}
      data-state={hasLoaded ? 'loaded' : 'idle'}>
      {src && (
        <Image
          aria-hidden
          className={styles.image}
          src={src}
          fill
          alt={alt}
          onLoad={() => setHasLoaded(true)}
        />
      )}
      {fallback && !hasLoaded && (
        <span className={styles.fallback}>{fallback}</span>
      )}
    </div>
  );
};

Avatar.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  fallback: PropTypes.string,
  fill: PropTypes.oneOf(['default', 'accent']),
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  src: PropTypes.string,
};

export default Avatar;
