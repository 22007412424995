import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import BevelBorder from './BevelBorder';
import CircleBorder from './CircleBorder';

import styles from './StyledNumber.module.css';

const StyledNumber = ({ className, number }) => {
  const isOdd = Math.abs(number % 2) == 1;
  return (
    <div className={cn(styles.root, className)}>
      <span className={styles.number}>{number}</span>
      <span className={styles.border} aria-hidden>
        {isOdd ? <BevelBorder className={styles.rotate} /> : <CircleBorder />}
      </span>
    </div>
  );
};

StyledNumber.propTypes = {
  className: PropTypes.string,
  number: PropTypes.number.isRequired,
};

export default StyledNumber;
