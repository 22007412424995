import React from 'react';

import PropTypes from 'prop-types';

import Badge from '../Badge';
import Flex from '../Flex';
import StatisticItem from '../StatisticItem';
import Text from '../Text';

import styles from './Snapshot.module.css';

const Snapshot = ({
  title,
  size = 's',
  subtitle,
  icon,
  badges = [],
  statistics = [],
  actions = null,
}) => {
  const statisticsSize = size === 's' ? 's' : 'm';

  return (
    <div className={styles.root} data-size={size}>
      <Flex gap="s" align="baseline">
        {icon && <span className={styles.icon}>{icon}</span>}
        <div className={styles.title}>{title}</div>
        {subtitle && (
          <Text size="s" font="mono" className={styles.subtitle}>
            {subtitle}
          </Text>
        )}
        {statistics.length > 0 && (
          <Flex align="baseline" className={styles.statistics}>
            {statistics.map((n, i) => (
              <StatisticItem key={i} {...n} size={statisticsSize} />
            ))}
          </Flex>
        )}
        {badges.length > 0 && (
          <Flex gap="2xs">
            {badges.map(({ label, variant = 'info' }, i) => (
              <Badge key={i} variant={variant}>
                {label}
              </Badge>
            ))}
          </Flex>
        )}
      </Flex>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

Snapshot.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['s', 'm']),
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
      label: PropTypes.string.isRequired,
    }),
  ),
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      datum: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }),
  ),
  actions: PropTypes.node,
};

export default Snapshot;
