'use client';

import React, { cloneElement } from 'react';

import { Slot } from '@radix-ui/react-slot';
import cn from 'classnames';
import PropTypes from 'prop-types';

import LoadingSpinner from '../LoadingSpinner';

import styles from './Button.module.css';

const Button = React.forwardRef(
  (
    {
      asChild = false,
      children,
      className,
      colorScheme = 'accent',
      disabled = false,
      fullWidth = false,
      isProcessing = false,
      onClick,
      size = 'm',
      variant = 'primary',
      ...rest
    },
    ref,
  ) => {
    if (
      (colorScheme === 'danger' || colorScheme === 'warning') &&
      variant !== 'primary'
    ) {
      throw new Error(
        'Warning and danger buttons should only be used in the primary variant, so they get full emphasis',
      );
    }

    const props = {
      'data-variant': variant,
      'data-size': size,
      'data-color-scheme': colorScheme,
      'data-full-width': fullWidth || null,
      'data-processing': isProcessing || null,
    };

    const shouldBeDisabled = disabled || isProcessing;

    const Comp = asChild ? Slot : 'button';

    const maybeLoadingSpinner = isProcessing ? (
      <span className={styles.processing}>
        <LoadingSpinner size={size} />
      </span>
    ) : null;

    /**
     * In order to keep the asChild behavior with the inline loading
     * spinner we need to clone the child and add the spinner as a sibling
     * to the child. This is needed because the Slot component only expects
     * a single child that it can clone all of the button props to.
     *
     * A common example is using a framework specific Link component that
     * needs to look like a Button.
     *
     * <Button asChild isProcessing onClick={handleClick}>
     *   <Link href="#">
     *     My Link
     *   </Link>
     * </Button>
     *
     * Cloning the element makes sure we end up with the DOM structure we
     * want for the above example:
     *
     * <Link onClick={handleClick} href="#">
     *   <span className={styles.content}>My Link</span>
     *   <span className={styles.processing}>{...}</span>
     * </Link>
     */
    const preparedChildren = asChild ? (
      cloneElement(
        children,
        null,
        <span className={styles.content}>{children.props.children}</span>,
        maybeLoadingSpinner,
      )
    ) : (
      <>
        <span className={styles.content}>{children}</span> {maybeLoadingSpinner}
      </>
    );

    return (
      <Comp
        className={cn(styles.root, className)}
        disabled={shouldBeDisabled}
        onClick={onClick}
        ref={ref}
        {...props}
        {...rest}>
        {preparedChildren}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

Button.propTypes = {
  asChild: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  colorScheme: PropTypes.oneOf([
    'accent',
    'text',
    'dark',
    'light',
    'card',
    'warning',
    'danger',
    'onboarding',
    'premium',
    'input',
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'outline']),
};

export default Button;
