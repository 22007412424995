import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StaticSlot from '../StaticSlot';

import styles from './Container.module.css';

const Container = ({
  asChild = false,
  children,
  className,
  justify = 'center',
  width = 'l',
  ...rest
}) => {
  const Comp = asChild ? StaticSlot : 'div';

  return (
    <Comp
      className={cn(styles.root, className)}
      data-width={width}
      data-justify={justify}
      {...rest}>
      {children}
    </Comp>
  );
};

Container.propTypes = {
  asChild: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  justify: PropTypes.oneOf(['left', 'center', 'right']),
  width: PropTypes.oneOf(['xs', 's', 'm', 'l']),
};

export default Container;
