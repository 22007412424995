import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Divider.module.css';

const Divider = ({ className, children, orientation = 'horizontal' }) => {
  return (
    <div
      className={cn(styles.root, className)}
      data-orientation={orientation}
      role="separator">
      {children && <span className={styles.content}>{children}</span>}
    </div>
  );
};

Divider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

export default Divider;
