import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Copy, Resynchronize } from '@cloudsmith/icons';

import LoadingSpinner from '../LoadingSpinner';

import styles from './TokenCopyField.module.css';

const copyToClipboard = async (copyText) => {
  return navigator.clipboard.writeText(copyText);
};

const TokenCopyField = ({
  copyLabel = 'Copy',
  disabled,
  isProcessing,
  refreshDisabled,
  refreshLabel = 'Refresh',
  token,
  size = 'm',
  onCopy,
  onRefresh,
}) => {
  return (
    <div className={cn(styles.root)} data-size={size}>
      <input
        type="text"
        value={token}
        className={cn(styles.textField)}
        readOnly
      />
      {onRefresh && (
        <button
          aria-label={refreshLabel}
          className={cn(styles.refreshButton)}
          disabled={refreshDisabled || isProcessing}
          onClick={onRefresh}>
          {isProcessing ? (
            <LoadingSpinner size="s" />
          ) : (
            <Resynchronize className={styles.refreshButtonIcon} />
          )}
        </button>
      )}
      <button
        className={cn(styles.copyButton)}
        disabled={disabled}
        onClick={async () => {
          await copyToClipboard(token);
          onCopy?.();
        }}>
        <Copy className={styles.copyButtonIcon} />
        {copyLabel}
      </button>
    </div>
  );
};

TokenCopyField.propTypes = {
  copyLabel: PropTypes.string,
  disabled: PropTypes.bool,
  isProcessing: PropTypes.bool,
  refreshDisabled: PropTypes.bool,
  refreshLabel: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  token: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default TokenCopyField;
