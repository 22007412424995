import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StaticSlot from '../StaticSlot';

import styles from './Eyebrow.module.css';

const Eyebrow = ({
  as = 'div',
  asChild,
  className,
  children,
  size = 'm',
  ...rest
}) => {
  const Comp = asChild ? StaticSlot : as;

  return (
    <Comp className={cn(styles.root, className)} data-size={size} {...rest}>
      {children}
    </Comp>
  );
};

Eyebrow.propTypes = {
  as: PropTypes.oneOf(['div', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  asChild: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};

export default Eyebrow;
