'use client';

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {
  SelectProvider as AriakitSelectProvider,
  Select as AriakitSelect,
  SelectPopover as AriakitSelectPopover,
  SelectItem as AriakitSelectItem,
  SelectItemCheck as AriakitSelectItemCheck,
  SelectGroup as AriakitSelectGroup,
  SelectGroupLabel as AriakitSelectGroupLabel,
} from '@ariakit/react';

import { ChevronDown, Tick } from '@cloudsmith/icons';

import { ensureArray } from '../../../util/array';

import SelectValueOrPlaceholder from '../../../internal/SelectValueOrPlaceholder';
import VirtualizedSelectList from '../../../internal/VirtualizedSelectList';

import styles from './Select.module.css';

export const Select = React.forwardRef(
  (
    {
      children,
      disabled,
      hasErrors,
      hasHint,
      hintId,
      id,
      multiSelect,
      name,
      onValueChange,
      placeholder,
      sameWidthPopover = true,
      size = 'm',
      value,
      variant = 'default',
      virtualizeList = false,
      virtualizeEstimatedItemSize = 36,
      ...props
    },
    forwardedRef,
  ) => {
    const handleSetValue = (v) => {
      if (multiSelect) {
        onValueChange(ensureArray(v));
      } else {
        onValueChange(v);
      }
    };

    return (
      <div className={styles.root}>
        <AriakitSelectProvider
          value={multiSelect ? ensureArray(value) : value}
          setValueOnMove={false}
          setValue={handleSetValue}
          name={name}>
          <AriakitSelect
            ref={forwardedRef}
            aria-invalid={hasErrors ?? null}
            aria-describedby={hasHint ? hintId : null}
            className={styles.trigger}
            data-size={size}
            data-variant={variant}
            disabled={disabled}
            id={id}
            {...props}>
            <SelectValueOrPlaceholder
              disabled={disabled}
              groupComponentName="SelectGroup"
              multiSelect={multiSelect}
              value={value}
              placeholder={
                <span className={styles.triggerPlaceholder}>{placeholder}</span>
              }
              items={children}
            />
            <ChevronDown className={styles.triggerIcon} />
          </AriakitSelect>
          <AriakitSelectPopover
            autoFocusOnShow
            className={styles.menu}
            data-variant={variant}
            data-size={size}
            gutter={4}
            moveOnKeyPress
            sameWidth={sameWidthPopover}
            unmountOnHide>
            {virtualizeList ? (
              <VirtualizedSelectList
                className={styles.listbox}
                items={React.Children.toArray(children)}
                estimatedItemSize={virtualizeEstimatedItemSize}
              />
            ) : (
              <div className={styles.listbox}>{children}</div>
            )}
          </AriakitSelectPopover>
        </AriakitSelectProvider>
      </div>
    );
  },
);

Select.displayName = 'Select';
Select.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  multiSelect: PropTypes.bool,
  name: PropTypes.string,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  sameWidthPopover: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  variant: PropTypes.oneOf(['default', 'unstyled']),
  virtualizeList: PropTypes.bool,
  virtualizeEstimatedItemSize: PropTypes.number,
};

export const SelectItem = React.forwardRef(
  ({ children, className, disabled, value, ...props }, forwardedRef) => {
    return (
      <AriakitSelectItem
        {...props}
        ref={forwardedRef}
        className={cn(styles.item, className)}
        disabled={disabled}
        value={value}>
        {children}
        <AriakitSelectItemCheck
          className={styles.itemIcon}
          render={({ children, ...rest }) =>
            children ? <Tick {...rest} /> : null
          }
        />
      </AriakitSelectItem>
    );
  },
);

SelectItem.displayName = 'SelectItem';
SelectItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const SelectGroup = ({ children, label }) => {
  return (
    <AriakitSelectGroup className={styles.group}>
      {label && (
        <AriakitSelectGroupLabel className={styles.groupLabel}>
          {label}
        </AriakitSelectGroupLabel>
      )}
      {children}
    </AriakitSelectGroup>
  );
};

SelectGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

SelectGroup.displayName = 'SelectGroup';
