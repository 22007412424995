/**
 * Rounds a number with a given decimal precision.
 *
 * @param {number} num
 * @param {number} precision
 * @returns {number}
 */
export const roundWithPrecision = (num, precision = 2) => {
  if (num == null) return null;

  const power = Math.pow(10, precision);
  return Math.round((parseFloat(num) + Number.EPSILON) * power) / power;
};

/**
 * Gets the number of decimal places in a number.
 *
 * @param {number} value
 * @returns {number}
 */
export const countDecimalPlaces = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};
