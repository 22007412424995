'use client';

import React from 'react';
import PropTypes from 'prop-types';
import * as RadixPopover from '@radix-ui/react-popover';

const Popover = ({ children, ...rest }) => {
  return <RadixPopover.Root {...rest}>{children}</RadixPopover.Root>;
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Popover;
