import React from 'react';

import * as RadixProgress from '@radix-ui/react-progress';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Progress.module.css';

const Progress = ({
  animated = false,
  className,
  progress,
  variant = 'accent',
  style,
  ...rest
}) => {
  return (
    <RadixProgress.Root
      className={cn(styles.root, className)}
      style={style}
      value={progress}
      data-variant={variant}
      {...rest}>
      <RadixProgress.Indicator
        data-animated={animated}
        className={styles.indicator}
        style={{
          '--indicator-width': `${progress}%`,
        }}
      />
    </RadixProgress.Root>
  );
};

Progress.propTypes = {
  animated: PropTypes.bool,
  className: PropTypes.string,
  progress: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['accent', 'accent-dark']),
  style: PropTypes.object,
};

export default Progress;
