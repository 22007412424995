'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixSlider from '@radix-ui/react-slider';

import { scales, inverseScales } from './util';
import { roundWithPrecision, countDecimalPlaces } from '../../../util/numbers';

import Text from '../../Text';

import styles from './Slider.module.css';

const Slider = React.forwardRef(
  (
    {
      className,
      defaultValue,
      dir,
      disabled,
      hasErrors,
      hasHint,
      hintId,
      id,
      inverted = false,
      labelMin,
      labelMax,
      max = 100,
      min = 0,
      minStepsBetweenThumbs = 0,
      name,
      onValueChange = () => { },
      onValueCommit = () => { },
      orientation = 'horizontal',
      showValue = true,
      scale = 'linear',
      step = 1,
      value,
      valuePrefix,
      valueSuffix,
      ...rest
    },
    ref,
  ) => {
    const thumbs = value || defaultValue;
    const hasTrackLabel = labelMin || labelMax;
    const precision = countDecimalPlaces(step);

    const applyScale = (v) => {
      return roundWithPrecision(
        min + scales[scale](v) * (max - min),
        precision,
      );
    };

    const normalizeValue = (v) => {
      if (!v) return v;
      return v.map((n) => inverseScales[scale]((n - min) / (max - min)));
    };

    const handleValueChange = (v) => {
      onValueChange(v.map(applyScale));
    };

    const handleValueCommit = (v) => {
      onValueCommit(v.map(applyScale));
    };

    return (
      <div
        className={cn(styles.root, className)}
        data-orientation={orientation}>
        <RadixSlider.Root
          aria-describedby={hasHint ? hintId : null}
          aria-invalid={hasErrors ?? null}
          className={cn(styles.slider)}
          defaultValue={normalizeValue(defaultValue)}
          dir={dir}
          disabled={disabled}
          id={id}
          inverted={inverted}
          max={1}
          min={0}
          minStepsBetweenThumbs={minStepsBetweenThumbs}
          name={name}
          onValueChange={handleValueChange}
          onValueCommit={handleValueCommit}
          orientation={orientation}
          ref={ref}
          step={step / max}
          value={normalizeValue(value)}
          {...rest}>
          <RadixSlider.Track className={cn(styles.track)}>
            <RadixSlider.Range className={cn(styles.range)} />
          </RadixSlider.Track>
          {thumbs.map((v, i) => (
            <RadixSlider.Thumb
              key={i}
              className={cn(styles.thumb)}
              data-value={roundWithPrecision(v, precision)}
              data-show-value={showValue}
              data-value-prefix={valuePrefix}
              data-value-suffix={valueSuffix}
            />
          ))}
        </RadixSlider.Root>
        {hasTrackLabel && (
          <div
            className={styles.trackLabels}
            data-orientation={orientation}
            data-inverted={inverted}
            data-dir={dir}>
            <Text size="s" className={styles.labelMin}>
              {valuePrefix ?? null}
              {labelMin}&#8202;
              {valueSuffix ?? null}
            </Text>
            <Text size="s" className={styles.labelMax}>
              {valuePrefix ?? null}
              {labelMax}&#8202;
              {valueSuffix ?? null}
            </Text>
          </div>
        )}
      </div>
    );
  },
);

Slider.displayName = 'Slider';

Slider.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  dir: PropTypes.oneOf(['ltr', 'rtl']),
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  inverted: PropTypes.bool,
  labelMin: PropTypes.string,
  labelMax: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  minStepsBetweenThumbs: PropTypes.number,
  name: PropTypes.string,
  onValueChange: PropTypes.func,
  onValueCommit: PropTypes.func,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  showValue: PropTypes.bool,
  scale: PropTypes.oneOf(['linear', 'quadratic', 'cubic', 'sqrt']),
  step: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.number),
  valuePrefix: PropTypes.string,
  valueSuffix: PropTypes.string,
};

export default Slider;
