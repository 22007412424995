import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StaticSlot from '../StaticSlot';

import styles from './Flex.module.css';

const Flex = ({
  align = 'center',
  justify = 'start',
  asChild,
  className,
  children,
  gap,
  inline = false,
  wrap = true,
  ...rest
}) => {
  const Comp = asChild ? StaticSlot : 'div';
  return (
    <Comp
      className={cn(styles.root, className)}
      data-align={align}
      data-justify={justify}
      data-gap={gap}
      data-inline={inline}
      data-wrap={wrap || null}
      {...rest}>
      {children}
    </Comp>
  );
};

Flex.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end', 'baseline', 'stretch']),
  justify: PropTypes.oneOf(['start', 'center', 'end', 'between']),
  asChild: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  gap: PropTypes.oneOf([
    'none',
    '3xs',
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
  ]),
  inline: PropTypes.bool,
  wrap: PropTypes.bool,
};

export default Flex;
