'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import styles from './TooltipContent.module.css';

const TooltipContent = ({
  arrowHeight,
  arrowWidth,
  children,
  className,
  padding = 's',
  side = 'top',
  variant = 'light',
  width = 's',
  ...rest
}) => {
  const props = {
    'data-padding': padding,
    'data-variant': variant,
    'data-width': width,
  };

  return (
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        className={cn(styles.content, className)}
        sideOffset={4}
        side={side}
        {...props}
        {...rest}>
        <div className={styles.inner}>{children}</div>
        <RadixTooltip.Arrow
          width={arrowWidth}
          height={arrowHeight}
          className={styles.arrow}
        />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  );
};

TooltipContent.propTypes = {
  arrowHeight: PropTypes.number,
  arrowWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  padding: PropTypes.oneOf(['s', 'm']),
  side: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  variant: PropTypes.oneOf(['accent', 'dark', 'light']),
  width: PropTypes.oneOf(['s', 'm', 'l']),
};

export default TooltipContent;
