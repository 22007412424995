import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import LogoSymbol from '../LogoSymbol';

import styles from './Loading.module.css';

const Loading = ({ className, message }) => {
  return (
    <div className={cn(styles.root, className)}>
      <LogoSymbol className={styles.symbol} fill="tertiary" />
      {message && <div className={styles.message}>{message}</div>}
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default Loading;
