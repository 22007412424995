import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ArrowLeft, ArrowRight } from '@cloudsmith/icons';

import { Select, SelectItem } from '../Select';

import { calculatePagination } from './util';

import * as styles from './Pagination.module.css';

const Pagination = ({
  className,
  page,
  totalPages,
  perPage,
  perPageOptions,
  onPageChange,
  onPerPageChange,
  labels = {},
}) => {
  const itemsToShow = useMemo(
    () => calculatePagination(page, totalPages),
    [page, totalPages],
  );

  const canGoBack = page > 1;
  const canGoForward = page < totalPages;

  return (
    <div className={cn(styles.root, className)}>
      <nav className={styles.nav}>
        <button
          className={styles.button}
          aria-label={labels.prevPage ?? 'Go to previous page'}
          disabled={!canGoBack}
          onClick={() => onPageChange(page - 1)}>
          <ArrowLeft className={styles.buttonIcon} />
        </button>

        {itemsToShow.map((item, idx) => {
          switch (item.type) {
            case 'button':
              return (
                <button
                  key={`button-${item.page}`}
                  className={styles.button}
                  data-state={item.page === page ? 'active' : null}
                  onClick={() => onPageChange(item.page)}>
                  {item.page}
                </button>
              );
            case 'divider':
              return (
                <div
                  aria-hidden
                  key={`divider-${idx}`}
                  className={styles.divider}>
                  …
                </div>
              );
          }
        })}

        <button
          className={styles.button}
          aria-label={labels.nextPage ?? 'Go to next page'}
          disabled={!canGoForward}
          onClick={() => onPageChange(page + 1)}>
          <ArrowRight className={styles.buttonIcon} />
        </button>
      </nav>

      {perPageOptions && perPageOptions.length > 0 && (
        <div className={styles.perPage}>
          <Select
            variant="unstyled"
            size="s"
            value={perPage}
            placeholder={labels.select ?? 'Select'}
            onValueChange={onPerPageChange}
            sameWidthPopover={false}>
            {perPageOptions.map((value) => (
              <SelectItem
                key={value}
                className={styles.perPageItem}
                value={value}>
                {value}
              </SelectItem>
            ))}
          </Select>
          <span className={styles.perPageLabel}>
            {labels.perPage ?? 'items per page'}
          </span>
        </div>
      )}
    </div>
  );
};

export const labelProps = {
  nextPage: PropTypes.string,
  perPage: PropTypes.string,
  prevPage: PropTypes.string,
  select: PropTypes.string,
};

Pagination.propTypes = {
  className: PropTypes.string,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  perPageOptions: PropTypes.arrayOf(PropTypes.number),
  onPageChange: PropTypes.func.isRequired,
  onPerPageChange: PropTypes.func,
  labels: PropTypes.shape(labelProps),
};

export default Pagination;
