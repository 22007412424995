'use client';

import {
  TableBody,
  TableCell,
  TableCellWithOverflow,
  TableColumnHeaderCell,
  TableHeader,
  TableRoot,
  TableRow,
  TableRowHeaderCell,
} from './Table';

const Table = TableRoot;

export {
  Table,
  TableRoot,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableCellWithOverflow,
  TableColumnHeaderCell,
  TableRowHeaderCell,
};
