import React from 'react';
import PropTypes from 'prop-types';
import * as RadixTabs from '@radix-ui/react-tabs';

import Stack from '../Stack';

const Tabs = ({ children, className, defaultValue, space = 'm', ...props }) => {
  return (
    <Stack asChild space={space}>
      <RadixTabs.Root className={className} defaultValue={defaultValue} {...props}>
        {children}
      </RadixTabs.Root>
    </Stack>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  space: PropTypes.oneOf([
    'none',
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
  ]),
};

export default Tabs;
