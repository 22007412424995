import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StaticSlot from '../StaticSlot';

import styles from './Card.module.css';

const Card = ({
  asChild,
  className,
  children,
  padding = 'm',
  variant,
  ...rest
}) => {
  const Comp = asChild ? StaticSlot : 'div';

  return (
    <Comp
      className={cn(styles.root, className)}
      data-padding={padding}
      data-variant={variant}
      {...rest}>
      {children}
    </Comp>
  );
};

Card.propTypes = {
  asChild: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  padding: PropTypes.oneOf(['none', 'xs', 's', 'm', 'l', 'xl']),
  variant: PropTypes.oneOf(['default', 'subdued', 'accent']),
};

export const CardHeader = ({
  asChild,
  children,
  className,
  padding,
  ...rest
}) => {
  const Comp = asChild ? StaticSlot : 'div';

  return (
    <Comp
      className={cn(styles.header, className)}
      data-padding={padding}
      {...rest}>
      {children}
    </Comp>
  );
};

CardHeader.propTypes = {
  asChild: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  padding: PropTypes.oneOf(['none', 'xs', 's', 'm', 'l', 'xl', '2xl']),
};

CardHeader.displayName = 'CardHeader';

export default Card;
