import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StaticSlot from '../StaticSlot';

import styles from './Grid.module.css';

const Grid = ({
  asChild,
  className,
  minColumnWidth,
  children,
  maxColumns,
  columnGap: _columnGap,
  rowGap: _rowGap,
  gap,
  stretch = false,
  ...rest
}) => {
  const Comp = asChild ? StaticSlot : 'div';

  if ((_rowGap || _columnGap) && gap) {
    throw new Error(
      'Please either specify `gap` or individual gap values for `rowGap` and `columnGap`. Not both.',
    );
  }

  if (stretch && maxColumns) {
    throw new Error(
      '`stretch` has no effect when specifying a maximum number of columns.',
    );
  }

  const rowGap = _rowGap ?? gap ?? null;
  const columnGap = _columnGap ?? gap ?? null;
  const gridRepeat = maxColumns
    ? maxColumns
    : stretch
    ? 'auto-fit'
    : 'auto-fill';

  return (
    <Comp
      className={cn(styles.root, className)}
      data-row-gap={rowGap}
      data-column-gap={columnGap}
      style={{
        '--grid-min-column-width': minColumnWidth,
        '--grid-repeat': gridRepeat,
      }}
      {...rest}>
      {children}
    </Comp>
  );
};

Grid.propTypes = {
  asChild: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  minColumnWidth: PropTypes.string,
  maxColumns: PropTypes.number,
  gap: PropTypes.oneOf(['2xs', 'xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl']),
  columnGap: PropTypes.oneOf([
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
  ]),
  rowGap: PropTypes.oneOf([
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
  ]),
  stretch: PropTypes.bool,
};

export default Grid;
