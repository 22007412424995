import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StaticSlot from '../StaticSlot';

import styles from './Stack.module.css';

const Stack = ({
  asChild,
  className,
  space = 'm',
  align = 'stretch',
  children,
  ...rest
}) => {
  const Comp = asChild ? StaticSlot : 'div';

  return (
    <Comp
      className={cn(styles.root, className)}
      data-space={space}
      data-align={align}
      {...rest}>
      {children}
    </Comp>
  );
};

Stack.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end', 'baseline', 'stretch']),
  asChild: PropTypes.bool,
  className: PropTypes.string,
  fitContent: PropTypes.bool,
  space: PropTypes.oneOf([
    'none',
    '3xs',
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
  ]),
  children: PropTypes.node.isRequired,
};

export default Stack;
