/**
 * Throttle a function to only run at most once every `wait` milliseconds.
 *
 * @param {Function} func
 * @param {number} wait
 * @returns {Function}
 */
export const throttle = (func, wait) => {
  let timeout;
  return function (...args) {
    if (!timeout) {
      timeout = setTimeout(() => {
        func(...args);
        timeout = null;
      }, wait);
    }
  };
};

/**
 * Utility to merge React refs. Useful if a component needs
 * an internal ref, but also uses `forwardRef`.
 *
 * @param {Array<React.Ref>} refs
 * @returns {React.RefCallback}
 */
export const mergeRefs = (...refs) => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
};
