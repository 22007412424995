import {
  Alpine as AlpineIcon,
  Cargo as CargoIcon,
  Chocolatey as ChocolateyIcon,
  Cocoapods as CocoapodsIcon,
  Composer as ComposerIcon,
  Conan as ConanIcon,
  Conda as CondaIcon,
  Cran as CranIcon,
  Dart as DartIcon,
  Deb as DebIcon,
  Docker as DockerIcon,
  Go as GoIcon,
  Gradle as GradleIcon,
  Helm as HelmIcon,
  Hex as HexIcon,
  Luarocks as LuarocksIcon,
  Maven as MavenIcon,
  Npm as NpmIcon,
  Nuget as NugetIcon,
  Powershell as PowershellIcon,
  Python as PythonIcon,
  Raw as RawIcon,
  Redhat as RedhatIcon,
  Ruby as RubyIcon,
  Sbt as SbtIcon,
  Swift as SwiftIcon,
  Terraform as TerraformIcon,
  Unity as UnityIcon,
  Vagrant as VagrantIcon,
} from '@cloudsmith/icons/formats';
import {
  Alpine as AlpineLogo,
  Chocolatey as ChocolateyLogo,
  Cocoapods as CocoapodsLogo,
  Composer as ComposerLogo,
  Conan as ConanLogo,
  Conda as CondaLogo,
  Cran as CranLogo,
  Dart as DartLogo,
  Debian as DebLogo,
  Docker as DockerLogo,
  Go as GoLogo,
  Gradle as GradleLogo,
  Helm as HelmLogo,
  Hex as HexLogo,
  Lua as LuaLogo,
  Maven as MavenLogo,
  Npm as NpmLogo,
  Nuget as NugetLogo,
  Powershell as PowershellLogo,
  Python as PythonLogo,
  Raw as RawLogo,
  Redhat as RedhatLogo,
  Ruby as RubyLogo,
  Rust as RustLogo,
  Sbt as SbtLogo,
  Swift as SwiftLogo,
  Terraform as TerraformLogo,
  Unity as UnityLogo,
  Vagrant as VagrantLogo,
} from '@cloudsmith/icons/logos';

export const FORMAT_ICONS = {
  alpine: AlpineIcon,
  cargo: CargoIcon,
  chocolatey: ChocolateyIcon,
  cocoapods: CocoapodsIcon,
  composer: ComposerIcon,
  conan: ConanIcon,
  conda: CondaIcon,
  cran: CranIcon,
  dart: DartIcon,
  deb: DebIcon,
  docker: DockerIcon,
  go: GoIcon,
  gradle: GradleIcon,
  helm: HelmIcon,
  hex: HexIcon,
  luarocks: LuarocksIcon,
  maven: MavenIcon,
  npm: NpmIcon,
  nuget: NugetIcon,
  powershell: PowershellIcon,
  python: PythonIcon,
  raw: RawIcon,
  rpm: RedhatIcon,
  ruby: RubyIcon,
  sbt: SbtIcon,
  swift: SwiftIcon,
  terraform: TerraformIcon,
  unity: UnityIcon,
  vagrant: VagrantIcon,
};

export const FORMAT_LOGOS = {
  alpine: AlpineLogo,
  cargo: RustLogo,
  chocolatey: ChocolateyLogo,
  cocoapods: CocoapodsLogo,
  composer: ComposerLogo,
  conan: ConanLogo,
  conda: CondaLogo,
  cran: CranLogo,
  dart: DartLogo,
  deb: DebLogo,
  docker: DockerLogo,
  go: GoLogo,
  gradle: GradleLogo,
  helm: HelmLogo,
  hex: HexLogo,
  luarocks: LuaLogo,
  maven: MavenLogo,
  npm: NpmLogo,
  nuget: NugetLogo,
  powershell: PowershellLogo,
  python: PythonLogo,
  raw: RawLogo,
  rpm: RedhatLogo,
  ruby: RubyLogo,
  sbt: SbtLogo,
  swift: SwiftLogo,
  terraform: TerraformLogo,
  unity: UnityLogo,
  vagrant: VagrantLogo,
};

export const FORMATS = {
  ALPINE: 'alpine',
  CARGO: 'cargo',
  COCOAPODS: 'cocoapods',
  COMPOSER: 'composer',
  CONAN: 'conan',
  CONDA: 'conda',
  CRAN: 'cran',
  DART: 'dart',
  DEB: 'deb',
  DOCKER: 'docker',
  GO: 'go',
  HELM: 'helm',
  HEX: 'hex',
  LUAROCKS: 'luarocks',
  MAVEN: 'maven',
  NPM: 'npm',
  NUGET: 'nuget',
  P2: 'p2',
  PYTHON: 'python',
  RPM: 'rpm',
  RUBY: 'ruby',
  SWIFT: 'swift',
  TERRAFORM: 'terraform',
  VAGRANT: 'vagrant',
  RAW: 'raw',
};
