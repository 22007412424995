import React from 'react';

import * as RadixTabs from '@radix-ui/react-tabs';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './TabsTrigger.module.css';

const TabsTrigger = ({ children, className, disabled, value }) => {
  return (
    <RadixTabs.Trigger
      disabled={disabled}
      className={cn(styles.root, className)}
      value={value}>
      {children}
    </RadixTabs.Trigger>
  );
};

TabsTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default TabsTrigger;
