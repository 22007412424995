import React, { useRef } from 'react';

import cn from 'classnames';
import { useInView } from 'framer-motion';
import PropTypes from 'prop-types';

import StaticSlot from '../StaticSlot';

import styles from './Text.module.css';

const Text = ({
  allowHTML,
  allowMarkers,
  as = 'span',
  asChild,
  children,
  className,
  color,
  font = 'default',
  overflowWrap,
  size = 'm',
  tabularFigures,
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: 0.9, once: true });

  const Comp = asChild ? StaticSlot : as;

  const props = {
    className: cn(styles.root, className, { [styles.inView]: inView }),
    'data-color': color,
    'data-font': font,
    'data-html': allowHTML || null,
    'data-markers': allowMarkers || null,
    'data-overflow-wrap': overflowWrap || null,
    'data-size': size,
    'data-tabular-figures': tabularFigures || null,
  };

  const hasHTML = allowHTML || allowMarkers;

  return hasHTML ? (
    <Comp ref={ref} {...props} dangerouslySetInnerHTML={{ __html: children }} />
  ) : (
    <Comp {...props}>{children}</Comp>
  );
};

Text.propTypes = {
  allowHTML: PropTypes.bool,
  allowMarkers: PropTypes.bool,
  as: PropTypes.oneOf(['span', 'p', 'div']),
  asChild: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'alert',
    'success',
    'light',
    'premium',
    'accent',
  ]),
  font: PropTypes.oneOf(['default', 'mono']),
  overflowWrap: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  tabularFigures: PropTypes.bool,
};

export default Text;
