import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './HotKey.module.css';

const HotKey = ({ children, className, ...rest }) => {
  return (
    <span className={cn(styles.root, className)} {...rest}>
      {children}
    </span>
  );
};

HotKey.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default HotKey;
