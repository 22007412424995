import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StaticSlot from '../StaticSlot';

import styles from './VisuallyHidden.module.css';

const VisuallyHidden = ({
  as = 'div',
  asChild,
  className,
  children,
  focusable = false,
}) => {
  const Comp = asChild ? StaticSlot : as;

  return (
    <Comp
      className={cn(styles.root, className)}
      data-focusable={focusable ?? null}>
      {children}
    </Comp>
  );
};

VisuallyHidden.propTypes = {
  as: PropTypes.elementType,
  asChild: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  focusable: PropTypes.bool,
};

export default VisuallyHidden;
