import React, { useId } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from '../Checkbox';
import Explainer from '../Explainer';

import styles from './CheckboxInput.module.css';

const CheckboxInput = React.forwardRef(
  (
    {
      allowHTML,
      checked,
      className,
      disabled,
      errors,
      explainer,
      hint,
      label,
      name,
      onCheckedChange,
      size = 'm',
      colorScheme = 'primary',
      ...rest
    },
    forwardedRef,
  ) => {
    const inputId = useId();
    const hintId = useId();

    return (
      <div
        className={cn(styles.root, className)}
        data-size={size}
        data-color-scheme={colorScheme}
        data-disabled={disabled ? 'disabled' : null}>
        <div className={cn(styles.inputWrapper)}>
          <Checkbox
            disabled={disabled}
            id={inputId}
            name={name}
            aria-describedby={hint ? hintId : null}
            checked={checked}
            onCheckedChange={onCheckedChange}
            ref={forwardedRef}
            size={size}
            {...rest}
          />
        </div>
        <div>
          <div className={cn(styles.labelWrapper)}>
            {allowHTML ? (
              <label
                className={cn(styles.label)}
                htmlFor={inputId}
                dangerouslySetInnerHTML={{ __html: label }}
                data-html="true"
              />
            ) : (
              <label className={cn(styles.label)} htmlFor={inputId}>
                {label}
              </label>
            )}

            {explainer && <Explainer explainer={explainer} />}
          </div>

          {hint && !errors && (
            <div className={cn(styles.hint)} id={hintId}>
              {hint}
            </div>
          )}

          {errors && (
            <div className={cn(styles.error)} role="alert">
              {errors.message}
            </div>
          )}
        </div>
      </div>
    );
  },
);

CheckboxInput.displayName = 'CheckboxInput';
CheckboxInput.propTypes = {
  allowHTML: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  explainer: PropTypes.node,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onCheckedChange: PropTypes.func,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  colorScheme: PropTypes.oneOf(['primary', 'secondary']),
};

export default CheckboxInput;
