'use client';

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Copy } from '@cloudsmith/icons';

import styles from './CopyText.module.css';
import VisuallyHidden from '../VisuallyHidden';

const copyToClipboard = async (copyText) => {
  return navigator.clipboard.writeText(copyText);
};

const CopyText = ({
  children,
  className,
  copyText,
  iconLabel,
  prefixLabel,
  uppercase = true,
  variant = 'default',
  visuallyHideText = false,
  onCopy,
}) => {
  const textElement = visuallyHideText ? (
    <VisuallyHidden className={styles.hidden}>
      {prefixLabel && <span className={styles.prefixLabel}>{prefixLabel}</span>}
      {children}
    </VisuallyHidden>
  ) : (
    <>
      {prefixLabel && <span className={styles.prefixLabel}>{prefixLabel}</span>}
      {children}
    </>
  );
  return (
    <button
      className={cn(styles.root, className)}
      data-variant={variant}
      data-uppercase={uppercase || null}
      onClick={async () => {
        await copyToClipboard(copyText);
        if (onCopy) {
          onCopy();
        }
      }}>
      {textElement}
      <div className={styles.icon}>
        <Copy />
        {iconLabel && <span className={styles.iconLabel}>{iconLabel}</span>}
      </div>
    </button>
  );
};

CopyText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  copyText: PropTypes.string.isRequired,
  iconLabel: PropTypes.string,
  prefixLabel: PropTypes.string,
  uppercase: PropTypes.bool,
  variant: PropTypes.oneOf(['info', 'default', 'input', 'code']),
  visuallyHideText: PropTypes.bool,
  onCopy: PropTypes.func,
};

export default CopyText;
