'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixAccordion from '@radix-ui/react-accordion';

import Heading from '../Heading';

import { ChevronDown } from '@cloudsmith/icons';

import styles from './AccordionTrigger.module.css';

const AccordionTrigger = React.forwardRef(
  (
    {
      arrowPosition = 'left',
      as = 'h3',
      children,
      className,
      size = 'xs',
      ...rest
    },
    ref,
  ) => (
    <RadixAccordion.Header asChild>
      <RadixAccordion.Trigger
        className={cn(styles.root, className)}
        data-arrow-position={arrowPosition}
        ref={ref}
        {...rest}>
        <div className={styles.chevronWrapper} aria-hidden>
          <ChevronDown className={styles.chevronIcon} />
        </div>
        <Heading as={as} size={size}>
          {children}
        </Heading>
      </RadixAccordion.Trigger>
    </RadixAccordion.Header>
  ),
);

AccordionTrigger.displayName = 'AccordionTrigger';

AccordionTrigger.propTypes = {
  arrowPosition: PropTypes.oneOf(['left', 'right']),
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  props: PropTypes.object,
  size: PropTypes.string,
};

export default AccordionTrigger;
