import React from 'react';
import PropTypes from 'prop-types';

const BevelBorder = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.2695 0.5001L31.4867 4.71634L31.4999 27.3597L27.3588 31.4999L4.6919 31.4858L0.529111 27.3239L0.500264 4.63141L4.6325 0.511004L27.2695 0.5001Z"
        stroke="currentColor"
      />
    </svg>
  );
};

BevelBorder.propTypes = {
  className: PropTypes.string,
};

export default BevelBorder;
