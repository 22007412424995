import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import LogoSymbol from '../LogoSymbol';

import styles from './LoadingSpinner.module.css';

const LoadingSpinner = ({ className, size = 'm' }) => {
  return (
    <div
      className={cn(styles.root, className)}
      role="presentation"
      data-size={size}>
      <LogoSymbol className={styles.symbol} data-size={size} size="xs" />
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
};

export default LoadingSpinner;
