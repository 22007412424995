import React from 'react';

const CircleBorder = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19.5" stroke="currentColor" />
    </svg>
  );
};

export default CircleBorder;
