'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixAccordion from '@radix-ui/react-accordion';

import styles from './AccordionContent.module.css';

const AccordionContent = React.forwardRef(
  ({ children, className, ...rest }, ref) => (
    <RadixAccordion.Content
      className={cn(styles.root, className)}
      {...rest}
      ref={ref}>
      <div className={styles.children}>{children}</div>
    </RadixAccordion.Content>
  ),
);

AccordionContent.displayName = 'AccordionContent';

AccordionContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  props: PropTypes.object,
};

export default AccordionContent;
