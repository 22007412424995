'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Slot } from '@radix-ui/react-slot';

import styles from './ListLink.module.css';

const ListLink = React.forwardRef(
  (
    {
      as = 'a',
      asChild = false,
      className,
      extraItem,
      hoverToReveal = false,
      label,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : as;
    return (
      <Comp
        ref={ref}
        onClick={onClick}
        className={cn(styles.root, className)}
        data-hover-reveal={hoverToReveal || null}
        {...rest}>
        <span className={styles.label}>{label}</span>
        {extraItem && <span className={styles.extraItem}>{extraItem}</span>}
      </Comp>
    );
  },
);

ListLink.displayName = 'ListLink';

ListLink.propTypes = {
  as: PropTypes.elementType,
  asChild: PropTypes.bool,
  className: PropTypes.string,
  extraItem: PropTypes.node,
  hoverToReveal: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default ListLink;
