import React from 'react';
import propTypes from 'prop-types';

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@cloudsmith/components';

import { FORMAT_ICONS } from '@cloudsmith/utils/formats/constants';
import { upCase } from '@cloudsmith/utils/formatters';

import styles from './FormatIconWithFallback.module.css';

const FormatIconWithFallback = ({
  format = '',
  noTooltip = true,
  size = 'm',
  variant = 'tertiary',
}) => {
  const Icon = FORMAT_ICONS[format];

  const abbreviatedFallback =
    format.length <= 2
      ? format.toUpperCase()
      : format.slice(0, 2).toUpperCase();

  const inner = (
    <div
      className={styles.root}
      data-size={size}
      data-variant={variant}
      aria-label={format}>
      {Icon ? (
        <Icon aria-hidden className={styles.icon} />
      ) : (
        <span aria-hidden className={styles.fallback}>
          {abbreviatedFallback}
        </span>
      )}
    </div>
  );

  return noTooltip ? (
    inner
  ) : (
    <Tooltip>
      <TooltipTrigger asChild>{inner}</TooltipTrigger>
      <TooltipContent>{upCase(format)}</TooltipContent>
    </Tooltip>
  );
};

FormatIconWithFallback.propTypes = {
  format: propTypes.string.isRequired,
  noTooltip: propTypes.bool,
  size: propTypes.oneOf(['s', 'm', 'l']),
  variant: propTypes.oneOf(['primary', 'tertiary']),
};

export default FormatIconWithFallback;
