'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';

import styles from './DropdownMenu.module.css';

const DropdownMenu = React.forwardRef(
  ({ children, className, ...props }, ref) => {
    return (
      <RadixDropdown.Portal>
        <RadixDropdown.Content
          {...props}
          className={cn(styles.root, className)}
          sideOffset={8}
          ref={ref}>
          {children}
        </RadixDropdown.Content>
      </RadixDropdown.Portal>
    );
  },
);

DropdownMenu.displayName = 'DropdownMenu';

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default DropdownMenu;
