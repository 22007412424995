import React, { useId } from 'react';

import PropTypes from 'prop-types';

import Flex from '../../components/Flex';
import FormInputLabel from '../../components/FormInputLabel';
import Stack from '../../components/Stack';
import Text from '../../components/Text';
import VisuallyHidden from '../../components/VisuallyHidden';

const ConditionallyWrappedLabel = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const extractErrorMessages = (errorData) => {
  let messages = [];
  let seenTypes = new Set();

  if (Array.isArray(errorData)) {
    errorData.forEach((item) => {
      if (item && item.message && !seenTypes.has(item.type)) {
        messages.push(item.message);
        seenTypes.add(item.type);
      }
    });
  } else if (
    typeof errorData === 'object' &&
    errorData.message &&
    !seenTypes.has(errorData.type)
  ) {
    messages.push(errorData.message);
    seenTypes.add(errorData.type);
  }

  return messages;
};

const asFormInput = (WrappedComponent) => {
  const FormInput = React.forwardRef(
    (
      {
        errors,
        explainer,
        extraElement,
        formInputClassName,
        hint,
        label,
        optional = false,
        optionalLabel = 'Optional',
        space = '2xs',
        visuallyHideLabel = false,
        ...rest
      },
      ref,
    ) => {
      const inputid = useId();
      const hintId = useId();

      const errorMessages = extractErrorMessages(errors);

      return (
        <Stack space={space} className={formInputClassName}>
          <ConditionallyWrappedLabel
            condition={visuallyHideLabel}
            wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}>
            <Flex justify="between">
              <FormInputLabel
                aria-hidden
                explainer={explainer}
                htmlFor={inputid}
                label={label}
                optional={optional}
                optionalLabel={optionalLabel}
              />
              {extraElement}
            </Flex>
          </ConditionallyWrappedLabel>

          <WrappedComponent
            hasErrors={errors ? true : null}
            hasHint={hint ? true : null}
            hintId={hintId}
            inputid={inputid}
            ref={ref}
            {...rest}
          />

          {hint && !errors && (
            <Text size="s" color="secondary" id={hintId}>
              {hint}
            </Text>
          )}

          {errorMessages.length > 0 && (
            <Stack space="3xs">
              {errorMessages.map((message, index) => (
                <Text key={index} size="s" color="alert" role="alert">
                  {message}
                </Text>
              ))}
            </Stack>
          )}
        </Stack>
      );
    },
  );

  FormInput.propTypes = {
    errors: PropTypes.object,
    explainer: PropTypes.node,
    extraElement: PropTypes.node,
    formInputClassName: PropTypes.string,
    hint: PropTypes.string,
    label: PropTypes.string.isRequired,
    optional: PropTypes.bool,
    optionalLabel: PropTypes.string,
    space: PropTypes.oneOf([
      'none',
      '3xs',
      '2xs',
      'xs',
      's',
      'm',
      'l',
      'xl',
      '2xl',
      '3xl',
      '4xl',
    ]),
    visuallyHideLabel: PropTypes.bool,
  };

  FormInput.displayName = 'FormInput';

  return FormInput;
};

export default asFormInput;
