import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import * as Icons from '@cloudsmith/icons';

import styles from './Icon.module.css';

const Icon = ({ icon, fill, size = 'm', className }) => {
  const IconComponent = Icons[icon];

  return (
    <div
      aria-hidden
      className={cn(styles.root, className)}
      data-fill={fill}
      data-size={size}>
      <IconComponent />
    </div>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(Icons)).isRequired,
  fill: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'accent',
    'success',
    'alert',
    'warning',
  ]),
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl']),
};

export default Icon;
