'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Slider from '../Base/Slider';
import asFormInput from '../../../internal/asFormInput';

import styles from './SliderInput.module.css';

const SliderInput = React.forwardRef(
  (
    {
      className,
      hasErrors,
      hasHint,
      hintId,
      inputId,
      labelMin,
      labelMax,
      onValueChange,
      orientation = 'horizontal',
      showValue = true,
      valuePrefix,
      valueSuffix,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <div
        className={cn(styles.root, className)}
        data-show-value={showValue}
        data-orientation={orientation}>
        <Slider
          hasErrors={hasErrors}
          hasHint={hasHint}
          hintId={hintId}
          id={inputId}
          ref={forwardedRef}
          labelMin={labelMin}
          labelMax={labelMax}
          onValueChange={onValueChange}
          orientation={orientation}
          showValue={showValue}
          valuePrefix={valuePrefix}
          valueSuffix={valueSuffix}
          {...props}
        />
      </div>
    );
  },
);

SliderInput.displayName = 'SliderInput';
SliderInput.propTypes = {
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputId: PropTypes.string,
  className: PropTypes.string,
  labelMin: PropTypes.string,
  labelMax: PropTypes.string,
  onValueChange: PropTypes.func,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  showValue: PropTypes.bool,
  valuePrefix: PropTypes.string,
  valueSuffix: PropTypes.string,
};

export default asFormInput(SliderInput);
