import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './QuickFilterToggle.module.css';

const QuickFilterToggle = ({
  active,
  label,
  className,
  count = null,
  variant = 'default',
  onClick,
  disabled,
  ...rest
}) => {
  const isEmpty = count !== null && Number(count) === 0;

  const dataAttributes = {
    'data-variant': isEmpty ? 'empty' : variant,
  };

  return (
    <button
      disabled={disabled}
      aria-current={active ? 'true' : null}
      className={cn(styles.root, className)}
      onClick={onClick}
      {...dataAttributes}
      {...rest}>
      {count !== null && <span className={styles.count}>{count}</span>}
      <span className={styles.label}>{label}</span>
    </button>
  );
};

QuickFilterToggle.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'alert', 'warning']),
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default QuickFilterToggle;
