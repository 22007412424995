'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Slot } from '@radix-ui/react-slot';

import styles from './Hyperlink.module.css';

const Hyperlink = React.forwardRef(
  (
    {
      as = 'a',
      asChild = false,
      children,
      className,
      hasUnderline = true,
      colorScheme = 'accent',
      onClick,
      ...rest
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : as;
    return (
      <Comp
        ref={ref}
        onClick={onClick}
        className={cn(styles.root, className)}
        data-has-underline={hasUnderline}
        data-color-scheme={colorScheme}
        {...rest}>
        {children}
      </Comp>
    );
  },
);

Hyperlink.displayName = 'Hyperlink';

Hyperlink.propTypes = {
  as: PropTypes.elementType,
  asChild: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasUnderline: PropTypes.bool,
  onClick: PropTypes.func,
  colorScheme: PropTypes.oneOf([
    'accent',
    'primary',
    'secondary',
    'tertiary',
    'light',
  ]),
};

export const HyperlinkIcon = ({ children }) => {
  return <span className={styles.icon}>{children}</span>;
};

HyperlinkIcon.propTypes = {
  children: PropTypes.node.isRequired,
};

HyperlinkIcon.displayName = 'HyperlinkIcon';

export default Hyperlink;
