'use client';

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './BaseTokenInput.module.css';

const BaseTokenInput = React.forwardRef(
  (
    {
      autoComplete = 'none',
      digits = 6,
      disabled,
      hasErrors,
      hasHint,
      hintId,
      id,
      name,
      value = '',
      onValueChange,
      onSubmit, 
      ...rest
    },
    ref,
  ) => {
    const rootRef = useRef(null);

    useEffect(() => {
      if (!rootRef.current) return;

      const el = rootRef.current;
      const bbox = el.getBoundingClientRect();

      const letterSpacing = bbox.width / digits;

      el.style.setProperty(
        '--token-input-letter-spacing',
        `${letterSpacing}px`,
      );
    }, [rootRef, digits]);

    const handleChange = (e) => {
      const newValue = e.target.value;
      onValueChange(newValue);

      // Submit form when the 6th digit is entered
      if (newValue.length === digits && onSubmit) {
        onSubmit();  
      }
    };

    return (
      <div
        className={styles.root}
        data-disabled={disabled || null}
        data-errors={hasErrors || null}
        ref={rootRef}
        style={{ '--token-input-digits': digits }}>
        <input
          ref={ref}
          aria-invalid={hasErrors}
          aria-describedby={hasHint ? hintId : null}
          autoComplete={autoComplete}
          disabled={disabled}
          id={id}
          name={name}
          type="text"
          inputMode="numeric"
          className={styles.input}
          size={digits}
          maxLength={digits}
          pattern={`\\d{${digits}}`}
          value={value}
          onChange={handleChange}
          {...rest}
        />
        <div className={styles.dividers} aria-hidden="true">
          {Array.from({ length: digits + 1 }).map((_, idx) => (
            <div key={idx} className={styles.divider} />
          ))}
        </div>
      </div>
    );
  },
);

BaseTokenInput.displayName = 'BaseTokenInput';

BaseTokenInput.propTypes = {
  autoComplete: PropTypes.oneOf(['none', 'one-time-code']),
  digits: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
};

export default BaseTokenInput;
