import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import classNames from 'react-day-picker/style.module.css';

import { ChevronDown } from '@cloudsmith/icons';

import { Popover, PopoverContent, PopoverTrigger } from '../../Popover';

import styles from './DatePicker.module.css';

const DatePicker = React.forwardRef(
  (
    {
      align = 'start',
      captionLayout,
      className,
      dateFormat = 'yyyy-MM-dd',
      disabled,
      hasErrors,
      hasHint,
      hintId,
      id,
      inputClassName,
      mode = 'single',
      name,
      onValueChange,
      placeholder = 'Select a date',
      selected: _selected,
      side,
      size = 'm',
      variant = 'default',
      ...rest
    },
    forwardedRef,
  ) => {
    const formatSingleValue = (value, dateFormat) => {
      return value ? format(value, dateFormat) : '';
    };
    const formatRangeValue = (range, dateFormat) => {
      return range && range.from && range.to
        ? `${format(range.from, dateFormat)} - ${format(range.to, dateFormat)}`
        : '';
    };

    const isRange = mode === 'range';

    const [selected, setSelected] = useState(_selected);

    const [inputValue, setInputValue] = useState(
      isRange
        ? formatRangeValue(_selected, dateFormat)
        : formatSingleValue(_selected, dateFormat),
    );

    useEffect(() => {
      setSelected(_selected);
      setInputValue(
        isRange
          ? formatRangeValue(_selected, dateFormat)
          : formatSingleValue(_selected, dateFormat),
      );
    }, [_selected, dateFormat, isRange]);

    const handleSingleSelect = (date) => {
      if (!date) {
        setInputValue('');
        setSelected(undefined);
        onValueChange && onValueChange(undefined);
      } else {
        setInputValue(format(date, dateFormat));
        setSelected(date);
        onValueChange && onValueChange(date);
      }
    };

    const handleRangeSelect = (range) => {
      if (!range) {
        setInputValue('');
        setSelected(undefined);
        onValueChange && onValueChange(undefined);
      } else {
        const formattedRange = `${format(range.from, dateFormat)} - ${format(range.to, dateFormat)}`;
        setInputValue(formattedRange);
        setSelected(range);
        onValueChange && onValueChange(range);
      }
    };

    const handleDayPickerSelect =
      mode === 'range' ? handleRangeSelect : handleSingleSelect;

    return (
      <div className={cn(styles.root, className)}>
        <Popover>
          <PopoverTrigger asChild>
            <button
              className={cn(styles.trigger, inputClassName)}
              data-size={size}
              data-variant={variant}
              disabled={disabled}
              ref={forwardedRef}
              name={name}
              aria-describedby={hasHint ? hintId : null}
              aria-invalid={hasErrors}
              id={id}>
              {/* <input
                readOnly
                aria-describedby={hasHint ? hintId : null}
                className={cn(styles.input, inputClassName)}
                disabled={disabled}
                data-size={size}
                data-variant={variant}
                aria-invalid={hasErrors}
                id={id}
                name={name}
                type="text"
                value={inputValue}
                placeholder={placeholder}
                ref={forwardedRef}
              /> */}

              {inputValue ? (
                <span>{inputValue}</span>
              ) : (
                <span className={styles.triggerPlaceholder}>{placeholder}</span>
              )}

              <ChevronDown className={styles.triggerIcon} />
            </button>
          </PopoverTrigger>
          <PopoverContent
            arrow={false}
            align={align}
            side={side}
            variant="input"
            width="auto">
            <DayPicker
              captionLayout={captionLayout}
              className={styles.dayPicker}
              classNames={classNames}
              mode={mode}
              onSelect={handleDayPickerSelect}
              selected={selected}
              {...rest}
            />
          </PopoverContent>
        </Popover>
      </div>
    );
  },
);

DatePicker.displayName = 'DatePicker';
DatePicker.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end']),
  captionLayout: PropTypes.oneOf([
    'label',
    'dropdown',
    'dropdown-months',
    'dropdown-years',
  ]),
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  mode: PropTypes.oneOf(['single', 'range']),
  name: PropTypes.string,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  side: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  size: PropTypes.oneOf(['s', 'm', 'l']),
  variant: PropTypes.oneOf(['default', 'unstyled']),
};

export default DatePicker;
