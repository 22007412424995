'use client';

import * as RadixPopover from '@radix-ui/react-popover';

const PopoverAnchor = RadixPopover.Anchor;
const PopoverTrigger = RadixPopover.Trigger;
import Popover from './Popover';
import PopoverContent from './PopoverContent';

export { Popover, PopoverAnchor, PopoverContent, PopoverTrigger };
