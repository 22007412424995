import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Code.module.css';

const Code = ({ children, className, ...rest }) => {
  return (
    <pre className={cn(styles.root, className)} {...rest}>
      {children}
    </pre>
  );
};

Code.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Code;
