'use client';

import React from 'react';

import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './DropdownMenuSeparator.module.css';

const DropdownMenuSeparator = React.forwardRef(
  ({ className, ...props }, ref) => {
    return (
      <RadixDropdown.Separator
        className={cn(styles.root, className)}
        {...props}
        ref={ref}
      />
    );
  },
);

DropdownMenuSeparator.displayName = 'DropdownMenuSeparator';

DropdownMenuSeparator.propTypes = {
  className: PropTypes.string,
};

export default DropdownMenuSeparator;
