'use client';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonIcon } from '../../Button';
import Flex from '../../Flex';

import { Close, Upload } from '@cloudsmith/icons';

import styles from './FilePicker.module.css';

export const FilePicker = React.forwardRef(
  (
    {
      disabled,
      hasErrors,
      hasHint,
      id,
      hintId,
      name,
      onValueChange,
      size = 'm',
      uploadText = 'Upload File',
      placeholder = 'No file selected',
      value,
      ...props
    },
    forwardedRef,
  ) => {
    const inputRef = useRef();
    const [filename, setFilename] = useState(null);

    const clearFile = () => {
      if (forwardedRef && forwardedRef.current)
        forwardedRef.current.value = null;
      inputRef.current.value = null;
      onValueChange?.(null);
      setFilename(null);
    };

    return (
      <>
        <Flex wrap={false} gap="3xs" align="stretch">
          <Button
            aria-describedby={hasHint ? hintId : null}
            aria-invalid={hasErrors}
            colorScheme="input"
            data-size={size}
            disabled={disabled}
            id={id}
            name={name}
            onClick={() => inputRef.current.click()}
            size={size}
            type="button"
            variant="secondary"
            {...props}>
            <ButtonIcon>
              <Upload />
            </ButtonIcon>
            {uploadText}
          </Button>
          <Flex className={styles.labelContainer} gap="3xs">
            <label
              className={styles.label}
              data-size={size}
              disabled={disabled}
              data-file-selected={filename}
              onClick={() => inputRef.current.click()}>
              {filename || placeholder}
            </label>
            {filename && <Close className={styles.close} onClick={clearFile} />}
          </Flex>
        </Flex>
        <input
          ref={(el) => {
            inputRef.current = el;
            if (forwardedRef) forwardedRef.current = el;
          }}
          className={styles.inputFile}
          type="file"
          onChange={(e) => {
            setFilename(e.target.files[0].name);
            onValueChange(e.target.files[0]);
          }}
          value={value}
        />
      </>
    );
  },
);

FilePicker.displayName = 'FilePicker';
FilePicker.propTypes = {
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onValueChange: PropTypes.func,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  uploadText: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
