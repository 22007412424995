import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as RadixTabs from '@radix-ui/react-tabs';

import styles from './TabsList.module.css';

const TabsList = ({ children, className }) => {
  return (
    <RadixTabs.List className={cn(styles.root, className)}>
      {children}
    </RadixTabs.List>
  );
};

TabsList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TabsList;
