'use client';

import React from 'react';

import * as RadixSwitch from '@radix-ui/react-switch';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './SwitchToggle.module.css';

const SwitchToggle = React.forwardRef(
  (
    {
      className,
      checked,
      disabled,
      id,
      name,
      onCheckedChange,
      variant = 'default',
    },
    ref,
  ) => {
    return (
      <RadixSwitch.Root
        ref={ref}
        className={cn(styles.root, className)}
        data-variant={variant}
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onCheckedChange={onCheckedChange}>
        <RadixSwitch.Thumb className={cn(styles.thumb)} />
      </RadixSwitch.Root>
    );
  },
);

SwitchToggle.displayName = 'SwitchToggle';

SwitchToggle.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onCheckedChange: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'green']),
};

export default SwitchToggle;
