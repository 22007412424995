import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './TextField.module.css';

const TextField = React.forwardRef(
  (
    {
      className,
      disabled,
      name,
      type = 'text',
      size = 'm',
      value,
      variant = 'default',
      onValueChange,
      hasErrors,
      inputClassName,
      placeholder,
      hasHint,
      hintId,
      id,
      multiline = false,
      prefix,
      suffix,
      ...rest
    },
    forwardedRef,
  ) => {
    const hasPrefixSuffix = !!(prefix || suffix);

    if (hasPrefixSuffix && multiline) {
      throw new Error(
        'Adding a prefix or a suffix to a textarea should be avoided',
      );
    }

    return (
      <div className={cn(styles.root, className)}>
        <div
          className={cn(styles.inputWrapper)}
          data-prefix-suffix={hasPrefixSuffix}
          aria-invalid={hasErrors}>
          {prefix && <div className={cn(styles.inputPrefix)}>{prefix}</div>}

          {multiline ? (
            <textarea
              aria-describedby={hasHint ? hintId : null}
              aria-invalid={hasErrors ?? null}
              className={cn(styles.textField, styles.textarea, inputClassName)}
              data-size={size}
              data-variant={variant}
              disabled={disabled}
              id={id}
              name={name}
              onChange={(e) => onValueChange?.(e.target.value)}
              placeholder={placeholder}
              ref={forwardedRef}
              value={value}
              {...rest}
            />
          ) : (
            <input
              aria-describedby={hasHint ? hintId : null}
              aria-invalid={hasErrors ?? null}
              className={cn(styles.textField, styles.input, inputClassName)}
              data-size={size}
              data-variant={variant}
              disabled={disabled}
              id={id}
              name={name}
              onChange={(e) => onValueChange?.(e.target.value)}
              placeholder={placeholder}
              ref={forwardedRef}
              type={type}
              value={value}
              {...rest}
            />
          )}

          {suffix && <div className={cn(styles.inputSuffix)}>{suffix}</div>}
        </div>
      </div>
    );
  },
);

TextField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  suffix: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'unstyled', 'code']),
};

TextField.displayName = 'TextField';

export default TextField;
