import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../Icon';

import * as styles from './TextList.module.css';

const TextList = ({ items = [], className, listStyle = 'diamond' }) => {
  return (
    <ul className={cn(styles.root, className)}>
      {items.map((item, index) => (
        <TextListItem key={index} listStyle={listStyle}>
          {item}
        </TextListItem>
      ))}
    </ul>
  );
};

TextList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  listStyle: PropTypes.oneOf(['tick', 'diamond']),
};

const TextListItem = ({ children, listStyle }) => {
  return (
    <li className={cn(styles.listItem)}>
      <span className={styles.icon}>
        {listStyle === 'tick' ? (
          <Icon icon="Tick" fill="accent" />
        ) : (
          <span className={styles.diamond} />
        )}
      </span>
      <span>{children}</span>
    </li>
  );
};

TextListItem.propTypes = {
  children: PropTypes.node,
  listStyle: PropTypes.oneOf(['tick', 'diamond']),
};

export default TextList;
