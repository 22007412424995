import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { Tick } from '@cloudsmith/icons';

import * as styles from './StepNavigation.module.css';

const StepNavigation = ({
  className,
  activeStep,
  allStepsCompleted = false,
  steps = [],
}) => {
  const activeStepIdx = steps.findIndex((step) => step === activeStep);

  return (
    <nav className={cn(styles.root, className)}>
      {steps.map((step, idx) => (
        <React.Fragment key={idx}>
          <button
            aria-current={
              !allStepsCompleted && step === activeStep ? 'step' : null
            }
            data-visited={
              allStepsCompleted || idx < activeStepIdx ? 'true' : null
            }
            disabled={step.disabled}
            className={styles.step}>
            {!allStepsCompleted && activeStepIdx <= idx ? (
              idx + 1
            ) : (
              <Tick className={styles.icon} />
            )}
          </button>
          {idx + 1 < steps.length && (
            <div
              className={styles.divider}
              data-visited={activeStep > idx + 1 ? 'true' : null}
            />
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

StepNavigation.propTypes = {
  activeStep: PropTypes.string,
  allStepsCompleted: PropTypes.bool,
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default StepNavigation;
