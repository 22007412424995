import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Announcement.module.css';

const Announcement = ({
  children,
  className,
  href,
  size = 'm',
  variant = 'info',
}) => {
  const Comp = href ? 'a' : 'div';
  return (
    <Comp
      href={href || null}
      className={cn(styles.root, className)}
      data-size={size}
      data-variant={variant}>
      {children}
    </Comp>
  );
};

Announcement.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  variant: PropTypes.oneOf([
    'error',
    'info',
    'premium',
    'subdued',
    'success',
    'warning',
    'dark',
  ]),
};

export default Announcement;
