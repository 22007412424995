'use client';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Heading from '../Heading';
import Stack from '../Stack';
import Text from '../Text';

import styles from './CardWithLogo.module.css';

const CardWithLogo = ({
  className,
  landscapeLogo = false,
  logo,
  onClick,
  text,
  title,
  ...rest
}) => {
  const hasOnClick = onClick;
  return (
    <Stack
      className={cn(styles.root, className, {
        [styles.hasOnClick]: hasOnClick,
      })}
      onClick={onClick}
      {...rest}>
      <Stack space="2xs">
        <Heading as="h4" size="2xs" className={cn(styles.title)}>
          {title}
        </Heading>
        {text && <Text className={cn(styles.text)}>{text}</Text>}
      </Stack>
      <div>
        {logo && (
          <div className={styles.logo} data-landscape={landscapeLogo}>
            {logo}
          </div>
        )}
      </div>
    </Stack>
  );
};

CardWithLogo.propTypes = {
  className: PropTypes.string,
  landscapeLogo: PropTypes.bool,
  logo: PropTypes.node,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default CardWithLogo;
