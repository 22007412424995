'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const ColumnsContext = createContext();

export const breakpointPropType = PropTypes.oneOf([
  'base',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'max',
]);

export const columnsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    breakpoint: breakpointPropType.isRequired,
    style: PropTypes.object,
  }),
);

export const ColumnsProvider = ({ value, children }) => {
  return (
    <ColumnsContext.Provider value={value}>{children}</ColumnsContext.Provider>
  );
};

ColumnsProvider.propTypes = {
  value: PropTypes.shape({
    columns: columnsPropType,
    tableRef: PropTypes.object.isRequired,
  }),
  children: PropTypes.node.isRequired,
};

export const useColumns = () => useContext(ColumnsContext);

export const useColumnBreakpoint = (cellRef) => {
  const { columns } = useColumns();
  const [breakpoint, setBreakpoint] = useState(null);

  const cellIndex = cellRef?.current?.cellIndex;

  useEffect(() => {
    if (cellRef.current == null || columns == null) return;

    const column = columns[cellIndex];
    setBreakpoint(column?.breakpoint);
  }, [cellRef, columns, cellIndex]);

  return breakpoint;
};

export const useVisibleColumns = () => {
  const { tableRef } = useColumns();
  const [visibleColumns, setVisibleColumns] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const columns = tableRef.current.querySelectorAll(':scope > colgroup > col');
      const colsArray = [].slice.call(columns);
      const displayedCols = colsArray.filter((el) => {
        return getComputedStyle(el).display !== 'none';
      }).length;
      setVisibleColumns(displayedCols);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [tableRef]);

  return visibleColumns;
};

export default ColumnsContext;
