import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useInView } from 'framer-motion';

import StaticSlot from '../StaticSlot';

import styles from './Heading.module.css';

const Heading = ({
  allowHTML,
  allowMarkers,
  as = 'h1',
  asChild,
  className,
  children,
  size = 'l',
  ...rest
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: 0.9, once: true });

  const Comp = asChild ? StaticSlot : as;

  const props = {
    className: cn(styles.root, className, { [styles.inView]: inView }),
    'data-size': size,
    'data-html': allowHTML || null,
    'data-markers': allowMarkers || null,
  };

  const hasHTML = allowHTML || allowMarkers;

  return hasHTML ? (
    <Comp
      ref={ref}
      {...props}
      {...rest}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  ) : (
    <Comp {...props} {...rest}>
      {children}
    </Comp>
  );
};

Heading.propTypes = {
  allowHTML: PropTypes.bool,
  allowMarkers: PropTypes.bool,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']),
  asChild: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['2xs', 'xs', 's', 'm', 'l', 'xl', '2xl']),
};

export default Heading;
