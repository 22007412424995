'use client';

import React, { useId } from 'react';

import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import PropTypes from 'prop-types';

import Explainer from '../Explainer';

import styles from './RadioGroup.module.css';

const RadioGroupItem = React.forwardRef(
  ({ align, children, disabled, explainer, value, ...props }, forwardedRef) => {
    const inputId = useId();
    return (
      <div
        data-align={align}
        className={styles.itemContainer}
        ref={forwardedRef}>
        <RadixRadioGroup.Item
          className={styles.radioGroupItem}
          disabled={disabled}
          value={value}
          id={inputId}
          {...props}>
          <RadixRadioGroup.Indicator className={styles.radioGroupIndicator} />
        </RadixRadioGroup.Item>
        <label
          data-disabled={disabled ? '' : undefined}
          className={styles.itemLabel}
          htmlFor={inputId}>
          {children}
        </label>

        {explainer && (
          <Explainer className={styles.explainer} explainer={explainer} />
        )}
      </div>
    );
  },
);

RadioGroupItem.displayName = 'RadioGroupItem';
RadioGroupItem.propTypes = {
  align: PropTypes.oneOf(['start', 'center']),
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  explainer: PropTypes.node,
  value: PropTypes.string.isRequired,
};

export default RadioGroupItem;
