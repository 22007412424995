import React from 'react';

import * as RadixCheckbox from '@radix-ui/react-checkbox';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Tick } from '@cloudsmith/icons';

import styles from './Checkbox.module.css';

const Checkbox = React.forwardRef(
  (
    { className, checked, disabled, id, name, onCheckedChange, size = 'm' },
    ref,
  ) => {
    return (
      <RadixCheckbox.Root
        ref={ref}
        className={cn(styles.root, className)}
        checked={checked}
        data-size={size}
        disabled={disabled}
        id={id}
        name={name}
        onCheckedChange={onCheckedChange}>
        <RadixCheckbox.Indicator className={styles.indicator}>
          <Tick />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
    );
  },
);

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onCheckedChange: PropTypes.func,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};

export default Checkbox;
