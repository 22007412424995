import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StaticSlot from '../StaticSlot';

import styles from './BigNumber.module.css';

const BigNumber = ({
  as = 'span',
  asChild,
  className,
  children,
  size = 'l',
  tabularFigures,
  ...rest
}) => {
  const Comp = asChild ? StaticSlot : as;

  const props = {
    className: cn(styles.root, className),
    'data-tabular-figures': tabularFigures || null,
    'data-size': size,
  };

  return (
    <Comp {...props} {...rest}>
      {children}
    </Comp>
  );
};

BigNumber.propTypes = {
  as: PropTypes.oneOf(['span', 'div']),
  asChild: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['2xs', 'xs', 's', 'm', 'l', 'xl', '2xl']),
  tabularFigures: PropTypes.bool,
};

export default BigNumber;
