'use client';

import React from 'react';

import PropTypes from 'prop-types';

import asFormInput from '../../../internal/asFormInput';
import DatePicker from '../Base/DatePicker';

const DatePickerInput = React.forwardRef(
  ({ hasErrors, hasHint, hintId, inputId, ...rest }, forwardedRef) => {
    return (
      <DatePicker
        hasErrors={hasErrors}
        hasHint={hasHint}
        hintId={hintId}
        id={inputId}
        ref={forwardedRef}
        {...rest}
      />
    );
  },
);

DatePickerInput.displayName = 'DatePickerInput';
DatePickerInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputClassName: PropTypes.string,
  inputId: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onValueChange: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default asFormInput(DatePickerInput);
