import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Explainer from '../Explainer';

import styles from './FormInputLabel.module.css';

const FormInputLabel = ({
  className,
  explainer,
  htmlFor,
  label,
  optional = false,
  optionalLabel = 'Optional',
}) => {
  return (
    <div className={cn(styles.root, className)}>
      <label className={cn(styles.label)} htmlFor={htmlFor}>
        {label}
      </label>
      {explainer && <Explainer explainer={explainer} />}
      {optional && (
        <span className={styles.optionalLabel}>{optionalLabel}</span>
      )}
    </div>
  );
};

FormInputLabel.propTypes = {
  className: PropTypes.string,
  explainer: PropTypes.node,
  htmlFor: PropTypes.string,
  label: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  optionalLabel: PropTypes.string,
};

export default FormInputLabel;
