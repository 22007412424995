import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

const ButtonIcon = ({ align, children }) => {
  return (
    <span className={styles.icon} data-align={align}>
      {children}
    </span>
  );
};

ButtonIcon.propTypes = {
  align: PropTypes.oneOf(['start', 'end']),
  children: PropTypes.node.isRequired,
};

ButtonIcon.displayName = 'ButtonIcon';

export default ButtonIcon;
