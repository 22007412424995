'use client';

import React from 'react';
import PropTypes from 'prop-types';

import { MultiSelectList } from '../Base/MultiSelectList';
import asFormInput from '../../../internal/asFormInput';

const MultiSelectListInput = React.forwardRef(
  (
    { children, hasErrors, hasHint, hintId, inputId, ...props },
    forwardedRef,
  ) => {
    return (
      <MultiSelectList
        hasErrors={hasErrors}
        hasHint={hasHint}
        hintId={hintId}
        id={inputId}
        ref={forwardedRef}
        {...props}>
        {children}
      </MultiSelectList>
    );
  },
);

MultiSelectListInput.displayName = 'MultiSelectListInput';
MultiSelectListInput.propTypes = {
  children: PropTypes.node.isRequired,
  forwardedRef: PropTypes.func,
  hasErrors: PropTypes.bool,
  hasHint: PropTypes.bool,
  hintId: PropTypes.string,
  inputId: PropTypes.string,
};

export default asFormInput(MultiSelectListInput);
