/**
 * Ensures a value is an array
 *
 * @param {any} value
 * @returns {Array}
 * @example
 * ensureArray('foo') // ['foo']
 * ensureArray(['foo']) // ['foo']
 */
export const ensureArray = (value) => {
  if (value === null || value === undefined) return [];
  return Array.isArray(value) ? value : [value];
};

