'use client';

export {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from './components/Accordion';
export { default as Announcement } from './components/Announcement';
export { default as Avatar } from './components/Avatar';
export { default as Badge, BadgeIcon } from './components/Badge';
export { default as BigNumber } from './components/BigNumber';
export { default as Button, ButtonIcon } from './components/Button';
export { default as Callout } from './components/Callout';
export { default as CallToActionCard } from './components/CallToActionCard';
export { default as Card, CardHeader } from './components/Card';
export { default as CardWithLogo } from './components/CardWithLogo';
export { default as Checkbox } from './components/Checkbox';
export { default as CheckboxInput } from './components/CheckboxInput';
export { default as Code } from './components/Code';
export {
  Combobox,
  ComboboxInput,
  ComboboxItem,
  ComboboxGroup,
} from './components/Combobox';

export { default as Container } from './components/Container';
export { default as CopyText } from './components/CopyText';
export { DatePicker, DatePickerInput } from './components/DatePicker';
export { default as Divider } from './components/Divider';
export { Dialog, DialogTrigger, DialogPanel } from './components/Dialog';
export {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownMenuSeparator,
  DropdownItem,
} from './components/Dropdown';
export { default as Explainer } from './components/Explainer';
export { default as Eyebrow } from './components/Eyebrow';
export { FilePicker, FilePickerInput } from './components/FilePicker';
export { default as Flex } from './components/Flex';
export { default as FormatIconWithFallback } from './components/FormatIconWithFallback';
export { default as FormInputLabel } from './components/FormInputLabel';
export { default as Grid } from './components/Grid';
export { default as Heading } from './components/Heading';
export { default as HotKey } from './components/HotKey';
export { default as Hyperlink, HyperlinkIcon } from './components/Hyperlink';
export { default as Icon } from './components/Icon';
export { default as ListLink } from './components/ListLink';
export { default as Loading } from './components/Loading';
export { default as LoadingSpinner } from './components/LoadingSpinner';
export { default as LoadingText } from './components/LoadingText';
export { default as Logo } from './components/Logo';
export { default as LogoSymbol } from './components/LogoSymbol';
export {
  MultiSelectList,
  MultiSelectListItem,
  MultiSelectListInput,
} from './components/MultiSelectList';
export { default as Pagination } from './components/Pagination';
export {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverTrigger,
} from './components/Popover';
export { default as Progress } from './components/Progress';
export { default as QuickFilterToggle } from './components/QuickFilterToggle';
export { RadioGroup, RadioGroupItem } from './components/RadioGroup';
export {
  Select,
  SelectInput,
  SelectItem,
  SelectGroup,
} from './components/Select';
export { Slider, SliderInput } from './components/Slider';
export { default as Snapshot } from './components/Snapshot';
export { default as Stack } from './components/Stack';
export { default as StaticSlot } from './components/StaticSlot';
export { default as StatisticItem } from './components/StatisticItem';
export { default as StepNavigation } from './components/StepNavigation';
export { default as StyledNumber } from './components/StyledNumber';
export { default as StyledNumberWithText } from './components/StyledNumberWithText';
export {
  SuggestionTextField,
  SuggestionTextFieldInput,
  SuggestionTextFieldTrigger,
  SuggestionTextFieldItem,
  SuggestionTextFieldGroup,
} from './components/SuggestionTextField';
export { SwitchToggle, SwitchToggleInput } from './components/SwitchToggle';
export {
  Table,
  TableHeader,
  TableRow,
  TableColumnHeaderCell,
  TableBody,
  TableRowHeaderCell,
  TableCell,
  TableCellWithOverflow,
} from './components/Table';
export { Tabs, TabsList, TabsTrigger, TabsContent } from './components/Tabs';
export { TagField, TagFieldInput } from './components/TagField';
export { default as Text } from './components/Text';
export { TextField, TextFieldInput } from './components/TextField';
export { default as TextList } from './components/TextList';
export { default as TokenCopyField } from './components/TokenCopyField';
export { BaseTokenInput, TokenInput } from './components/TokenInput';
export { default as Toast } from './components/Toast';
export {
  ToggleGroup,
  ToggleGroupItem,
  ToggleGroupInput,
} from './components/ToggleGroup';
export { Tooltip, TooltipTrigger, TooltipContent } from './components/Tooltip';
export { default as VisuallyHidden } from './components/VisuallyHidden';

/**
 * IMPORTANT: If updating these breakpoint values,
 * be sure to update equivalent values in /src/styles/breakpoints.css
 */

export const breakpoints = {
  xsmall: '400px',
  small: '700px',
  medium: '960px',
  large: '1200px',
  xlarge: '1550px',
  max: '1920px',
};

export const mediaQueries = {
  xsmall: `(min-width: ${breakpoints.xsmall})`,
  small: `(min-width: ${breakpoints.small})`,
  medium: `(min-width: ${breakpoints.medium})`,
  large: `(min-width: ${breakpoints.large})`,
  xlarge: `(min-width: ${breakpoints.xlarge})`,
  max: `(min-width: ${breakpoints.max})`,
};
