'use client';

import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { QuestionMark } from '@cloudsmith/icons';

import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';

import styles from './Explainer.module.css';

const Explainer = ({ className, explainer, inline = false, delayDuration }) => {
  return (
    <Tooltip delayDuration={delayDuration}>
      <TooltipTrigger asChild>
        <span
          className={cn(styles.explainerTrigger, className)}
          data-inline={inline}>
          <QuestionMark />
        </span>
      </TooltipTrigger>
      <TooltipContent>{explainer}</TooltipContent>
    </Tooltip>
  );
};

Explainer.propTypes = {
  className: PropTypes.string,
  explainer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  inline: PropTypes.bool,
  delayDuration: PropTypes.number,
};

export default Explainer;
