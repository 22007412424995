import React from 'react';
import PropTypes from 'prop-types';

import { Close } from '@cloudsmith/icons';

import Heading from '../Heading';
import Stack from '../Stack';
import Text from '../Text';

import styles from './Toast.module.css';

const Toast = ({
  actionLabel,
  allowHTML,
  allowUserClose = false,
  closeLabel = 'Close',
  message,
  onAction,
  onCloseIntent,
  title,
  variant = 'info',
}) => {
  const ariaLive = variant === 'error' ? 'assertive' : 'polite';

  return (
    <div aria-live={ariaLive} className={styles.root} data-variant={variant}>
      <Stack space="2xs">
        <Heading size="2xs" asChild>
          <div>{title}</div>
        </Heading>
        {message && (
          <Text as="div" allowHTML={allowHTML}>
            {message}
          </Text>
        )}
        {actionLabel && (
          <div className={styles.action}>
            <button className={styles.actionButton} onClick={onAction}>
              {actionLabel}
            </button>
          </div>
        )}
      </Stack>
      {allowUserClose && (
        <button
          aria-label={closeLabel}
          className={styles.close}
          onClick={onCloseIntent}>
          <Close className={styles.closeIcon} />
        </button>
      )}
    </div>
  );
};

Toast.propTypes = {
  actionLabel: PropTypes.string,
  allowHTML: PropTypes.bool,
  allowUserClose: PropTypes.bool,
  closeLabel: PropTypes.string,
  message: PropTypes.string,
  onAction: PropTypes.func,
  onCloseIntent: PropTypes.func,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
};

export default Toast;
