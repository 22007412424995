/**
 * Given a string value and the current index of the cursor
 * return the last word that was typed.
 *
 * @param {string} value
 * @param {number} currentIdx
 * @returns {string}
 */
const lookBackAtLastWord = (value, currentIdx) => {
  const word = [];

  for (let i = currentIdx - 1; i >= 0; i--) {
    const char = value[i];
    if (char === ' ') {
      break;
    }
    word.unshift(char);
  }
  const joined = word.join('');
  return joined || null;
};

/**
 * Matches the last word in the input field with the
 * available triggers.
 *
 * @param {HTMLInputElement} element
 * @param {string[]} triggers
 * @returns {string|null}
 */
export const getTrigger = (element, triggers) => {
  const { value, selectionStart } = element;

  const lastWord = lookBackAtLastWord(value, selectionStart);

  for (const trigger of triggers) {
    if (lastWord && lastWord.includes(trigger)) {
      return trigger;
    }
  }
};

/**
 * Splits the search value from the last word in the input
 * field by removing the trigger.
 *
 * @param {HTMLInputElement} element
 * @param {string} currentTrigger
 * @returns {string}
 */
export const getSearchValue = (element, currentTrigger) => {
  const { value, selectionStart } = element;
  const lastWord = lookBackAtLastWord(value, selectionStart) ?? '';

  return lastWord.slice(currentTrigger?.length ?? 0);
};

/**
 * Updates the value in the input element with the selected
 * typeahead suggestion.
 *
 * @param {Object} options
 * @param {HTMLInputElement} options.element
 * @param {string} options.searchValue
 * @param {string} options.value
 * @param {string} options.prevValue
 * @param {string} options.trigger
 * @param {boolean} options.replaceTrigger
 *
 * @returns {{ newValue: string; newCaretOffset: number }}
 */
export const replaceValue = ({
  element,
  searchValue,
  value,
  prevValue,
  trigger,
  replaceTrigger = false,
}) => {
  const { selectionStart } = element;
  const startIndex =
    selectionStart - searchValue.length - (replaceTrigger ? trigger.length : 0);
  const endIndex = selectionStart;

  const newValue =
    (prevValue ?? '').slice(0, startIndex) +
    value +
    (prevValue ?? '').slice(endIndex);

  return { newValue, newCaretOffset: startIndex + value.length };
};

/**
 * Finds the current trigger config from the list of
 * all available triggers.
 *
 * @param {string} trigger
 * @param {Object[]} allTriggers
 * @returns {Object}
 */
export const getCurrentTrigger = (trigger, allTriggers) => {
  return allTriggers.find(({ trigger: t }) => {
    if (trigger == null || trigger == undefined) return t == undefined;
    return t === trigger;
  });
};

/**
 * Gets the list of suggestions for the current trigger.
 *
 * @param {string} trigger
 * @param {Object[]} allTriggers
 * @returns {string[]}
 */
export const getCurrentList = (trigger, allTriggers) => {
  const list = getCurrentTrigger(trigger, allTriggers);
  return list?.items ?? [];
};
