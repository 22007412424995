import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Badge.module.css';

const Badge = ({
  as = 'span',
  children,
  className,
  font = 'mono',
  showAs,
  uppercase = true,
  variant,
  ...rest
}) => {
  const Comp = as;

  return (
    <Comp
      className={cn(styles.root, className)}
      data-show-as={showAs}
      data-variant={variant}
      data-font={font}
      data-uppercase={uppercase || null}
      {...rest}>
      {children}
    </Comp>
  );
};

Badge.propTypes = {
  as: PropTypes.oneOf(['span', 'p', 'div']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  showAs: PropTypes.oneOf(['badge', 'tag', 'inline']),
  uppercase: PropTypes.bool,
  font: PropTypes.oneOf(['mono', 'body']),
  variant: PropTypes.oneOf([
    'error',
    'info',
    'tag',
    'success',
    'warning',
    'outline',
    'inverted',
    'accent',
  ]),
};

export const BadgeIcon = ({ children }) => {
  return <span className={styles.icon}>{children}</span>;
};

BadgeIcon.propTypes = {
  children: PropTypes.node.isRequired,
};

BadgeIcon.displayName = 'BadgeIcon';

export default Badge;
